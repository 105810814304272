/** @format */

import { ChangeDetectorRef, Component, inject, Input, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import humanizeDuration from 'humanize-duration';
import { each, find, get, head, isEqual, isNil, join, last, map, round } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import moment, { Moment } from 'moment';
import { Equipment } from '../../../_classes/equipment/equipment.class';
import { ElevatorLastDoorStates } from '../../../_classes/equipment/equipment.elevator.class';
import { Extremes } from '../../../_classes/extremes.class';
import { CloseFailureOrigin, FieldUpdateOrigin, OpenFailureOrigin } from '../../../_classes/field-update-origin.class';
import {
  EquipmentDoorState,
  EquipmentDoorStateNameLabel,
} from '../../../_constants/equipment-door/equipment-door-state';
import { getFloorName } from '../../../_helpers/equipment.helper';
import { EquipmentFailureService } from '../../../_services/equipment-failure/equipment-failure.service';
import { ConfirmDialogService } from '../../confirm-dialog/confirm-dialog.service';
import { DatagridComponent, IDatagridOptions } from '../../datagrid/datagrid.component';
import { IRecord } from '../../datagrid/datasource';
import { IDatatableOptions } from '../../datagrid/datatable.class';
import { FailureDatagridDialogComponent } from './failure-datagrid-dialog/failure-datagrid-dialog.component';

export interface Record extends IRecord {
  start: string;
  end: string | null;
  isIgnored: boolean;
  openOrigin: FieldUpdateOrigin;
  closeOrigin: FieldUpdateOrigin;
  lastMouvement: {
    endDistance: number;
    end: string;
  };
  lastDoorState: {
    state: EquipmentDoorState;
    end: string;
  };
  triggeredDoubtRemovals: {
    date: string;
    sesioNodeComponentId: string;
    doubtRemovalComponentLabel: string;
    cabinCallDataId: string;
  }[];
}

@Component({
  selector: 'app-failure-datagrid',
  templateUrl: './failure-datagrid.component.html',
  styleUrls: ['./failure-datagrid.component.scss'],
})
export class FailureDatagridComponent {
  @Input('equipment')
  set setEquipment(equipment: Equipment) {
    if (isEqual(equipment, this.equipment)) return;
    this.equipment = equipment;
    if (this.equipment) this.loadPage();
  }
  equipment: Equipment;

  public setExtremes(extremes: Extremes): void {
    if (isEqual(extremes, this.extremes)) return;
    this.extremes = extremes;
    this.loadPage();
  }
  extremes?: Extremes;

  public highlightRow(_id: string | null | undefined) {
    const rows = this.datagrid?.rows || [];
    each(rows, (row) => delete row.backgroundColor);
    if (_id) {
      const row = find(rows, (row) => row._id === _id);
      if (row) row.backgroundColor = 'var(--app-color-secondary-rgba-25)';
    }
    this.datagrid?.changeDetectorRef.detectChanges();
  }

  options: IDatagridOptions<Record> = {
    pagination: { default: 1, options: [10, 20, 50, 100] },
    service: (options: IDatatableOptions) =>
      this.equipmentFailureService.datatable(options, {
        equipmentId: this.equipment._id,
        getIgnored: true,
        from: this.extremes?.start,
        to: this.extremes?.end,
      }),
    columns: [
      { property: 'isIgnored', label: ' ', cellTmpl: 'ignored', maxWidth: 80 },
      {
        property: 'start',
        label: $localize`Date début`,
        type: 'date',
        format: 'L LTS',
        maxWidth: 200,
      },
      { property: 'end', label: $localize`Date de fin`, type: 'date', format: 'L LTS', maxWidth: 200 },
      {
        property: 'duration',
        linkedProperties: ['duration', 'start', 'end'],
        label: $localize`Durée`,
        textAlign: 'end',
        displayWith: (record: any) => {
          if (!record.start) return '-';
          const end = record.end ? new Date(record.end).valueOf() : Date.now();
          const start = new Date(record.start).valueOf();
          return humanizeDuration(end - start, { language: 'fr', maxDecimalPoints: 0, largest: 1 });
        },
        color: (record: any) => (!record.isIgnored ? 'var(--app-color-danger)' : ''),
      },
      {
        property: 'position',
        linkedProperties: ['lastMouvement.endDistance', 'lastMouvement.end'],
        label: $localize`Position`,
        displayWith: (record: any) => {
          const floors = get(this.equipment, 'metadata.floors');
          if (!floors) return ``;
          const end = get(record, 'lastMouvement.endDistance');
          if (isNil(end)) return '-';
          const lastDate = get(record, 'lastMouvement.end');
          if (!lastDate) return '-';
          return $localize`<div>${getFloorName(floors, end) || '?'}</div><span nowrap light>${round(end / 100, 2)} m le ${moment(lastDate).format('L à LT')}</span>`;
        },
        maxWidth: 300,
      },
      {
        property: 'lastDoorStates',
        label: $localize`Porte`,
        displayWith: (record: any) => {
          if (!record.lastDoorStates) return '-';
          const lastDoorStates = new ModelMapper(ElevatorLastDoorStates).map(record.lastDoorStates);
          return join(
            map(
              lastDoorStates.toArray(),
              (doorState) =>
                `<div>${EquipmentDoorStateNameLabel[doorState.doorName][doorState.state]} <span nowrap light>(${moment(doorState.date).format('L LT')})</span></div>`,
            ),
          );
        },
        maxWidth: 300,
      },
      {
        property: 'triggeredDoubtRemovals',
        label: $localize`Levées de doute`,
        displayWith: (record: any) => {
          const triggeredDoubtRemovals = get(record, 'triggeredDoubtRemovals');
          if (!triggeredDoubtRemovals?.length) return '-';
          const startDate: Moment = moment(get(head(triggeredDoubtRemovals), 'date'));
          const endDate: Moment = moment(get(last(triggeredDoubtRemovals), 'date'));
          return $localize`
          <div nowrap>${triggeredDoubtRemovals.length} levées de doute déclenchées</div>
          <span nowrap light>entre ${moment(startDate).format('LT')} et ${moment(endDate).format('LT')}</span>
          `;
        },
        maxWidth: 300,
      },
      {
        property: 'openOrigin',
        label: $localize`Ouverture de la panne`,
        displayWith: (record: any) => OpenFailureOrigin(record.openOrigin),
        maxWidth: 300,
      },
      {
        property: 'closeOrigin',
        label: $localize`Fermeture de la panne`,
        displayWith: (record: any) => CloseFailureOrigin(record.closeOrigin),
        maxWidth: 300,
      },
    ],
    rowColor: (record: any) => (record.isIgnored ? '#aaa' : ''),
    sortedColumns: [{ column: 1, dir: 'desc' }],
    enableHideShowColumns: false,
    enableExport: true,
    enableRefresh: true,
    enableStickyColumn: false,
    enableReorderColumn: false,
    rowClick: async (datagrid: DatagridComponent<Record>, record: Record) => {
      this.dialog.open(FailureDatagridDialogComponent, {
        panelClass: ['dialog-no-padding'],
        autoFocus: false,
        data: {
          record,
          equipment: this.equipment,
          toggleIsIgnored: async (_id: string, isIgnored: boolean) => {
            const row = find(datagrid.rows, (row) => row._id === record._id);
            if (!row) return;
            const cell = row.cells[0];
            if (cell) {
              row.color = isIgnored ? '#aaa' : null;
              cell.value = cell.rawValue = isIgnored;
            }
            const durationCell = row.cells[3];
            if (durationCell) durationCell.color = isIgnored ? '#aaa' : 'var(--app-color-danger)';

            if (moment(record.end).isSameOrAfter(moment().subtract(12, 'months').startOf('day'))) {
              this.equipment.failureCount = this.equipment.failureCount + (isIgnored ? -1 : 1);
              const duration = moment(record.end).diff(moment(record.start));
              this.equipment.failureDuration = this.equipment.failureDuration + (isIgnored ? -duration : duration);
              const period = moment().startOf('day').diff(moment().subtract(12, 'months').startOf('day'));
              this.equipment.disponibilityRate = 1 - this.equipment.failureDuration / period;
              this.changeDetectorRef.detectChanges();
            }

            datagrid.changeDetectorRef.detectChanges();
          },
        },
      });
    },
  };
  @ViewChild('datagrid', { read: DatagridComponent, static: false })
  datagrid: DatagridComponent;

  private equipmentFailureService = inject(EquipmentFailureService);
  private confirmDialogService = inject(ConfirmDialogService);
  private dialog = inject(MatDialog);
  public changeDetectorRef = inject(ChangeDetectorRef);

  private loadPage() {
    if (this.datagrid) this.datagrid.loadPage();
  }
}
