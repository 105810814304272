<div #container id="container" fxLayout="column" intersectionObserver (intersection)="ready($event.intersect)"
  [class.has-floating-acionts]="actionsPosition === 'floating'" [class.fullscreen]="isFullscreen">

  <ng-container *ngTemplateOutlet="headerTmpl"></ng-container>

  <div id="content" fxFlex="grow">
    <table mat-table [fixedLayout]="false" [dataSource]="rows">

      <ng-container *ngFor="let header of headers; index as index" [matColumnDef]="header.label"
        [sticky]="header.sticky">
        <th mat-header-cell *matHeaderCellDef class="column-{{header.type}}" [class.column-sticky]="header.sticky"
          [style.max-width.px]="header.maxWidth">
          <div fxLayoutAlign="space-between center" class="cell">
            <ng-container *ngTemplateOutlet="tableHeaderTmpl; context: { header: header }"></ng-container>
            <ng-container *ngIf="header.type !== 'action'">
              <ng-container *ngTemplateOutlet="tableHeaderActionsTmpl; context: { header: header }"></ng-container>
            </ng-container>
          </div>
        </th>
        <td mat-cell *matCellDef="let row" [style.--column-padding-left]="header.paddingLeft"
          class="column-{{header.type}}" [class.column-sticky]="header.sticky" intersectionObserver
          (intersection)="row.cells[header.index].render = row.cells[header.index].render || $event.intersect"
          [style.max-width.px]="header.maxWidth" [matTooltip]="row.tooltip">
          <div *ngIf="row.cells[header.index].bgColor"
            [style]="{ position: 'absolute', 'background-color': row.cells[header.index].bgColor, top: 0, right: 0, bottom: 0, left: 0 }">
          </div>
          <ng-container *ngIf="row.cells[header.index].render === true">
            <ng-container
              *ngTemplateOutlet="tableCellTmpl; context: { header: header, row: row, cell: row.cells[header.index]  }">
            </ng-container>
          </ng-container>
        </td>
      </ng-container>

      <tr id="header" mat-header-row *matHeaderRowDef="(headers | pluck: 'label'); sticky: true"></tr>
      <tr class="row" [class.has-click-action]="!!rowClickOption" mat-row
        *matRowDef="let row; columns: (headers | pluck: 'label');" (click)="rowClick($event, row)"
        [style.color]="row.color" [style.background-color]="row.backgroundColor"></tr>
      <tr class="mat-row" *matNoDataRow>
        <td *ngIf="!loading" class="mat-cell no-data" [attr.colspan]="headers?.length">
          <span translate>no-data</span>
        </td>
      </tr>
    </table>

    <div id="spinner" *ngIf="loading" @fade>
      <div id="blur"></div>
      <div id="tmpl" fxLayoutAlign="center center">
        <ng-container *ngTemplateOutlet="spinnerTmpl"></ng-container>
      </div>
    </div>
  </div>

  <mat-paginator id="paginator" [length]="dataSource?.recordsFiltered"
    [pageSize]="pagination.options[pagination.default]" [showFirstLastButtons]="true"
    [style.display]="(showPagination === 'never' || (showPagination === 'auto' && (loading || !dataSource || !rows || dataSource.recordsFiltered <= rows.length))) ? 'none' : undefined">
  </mat-paginator>
</div>

<!-- TOP of table -->
<ng-template #headerTmpl>
  <div id="top" fxLayoutAlign="start center">
    <ng-content select="[datagrid-indicators]"></ng-content>

    <div id="top-actions"
      *ngIf="['top', 'floating'].includes(actionsPosition) && (actions?.length || enableHideShowColumns || enableExport || enableRefresh || enableFullscreen)"
      fxLayoutAlign="end center" fxLayoutGap="8px" [class.floating]="actionsPosition === 'floating'"
      [class.fullscreen]="isFullscreen">

      <ng-container *ngTemplateOutlet="actionsTmpl; context: { actions: actions }"></ng-container>

      <div id="column-options" *ngIf="enableHideShowColumns || enableStickyColumn || enableReorderColumn">
        <button mat-icon-button color="primary" class="icon-only action" i18n-matTooltip
          matTooltip="Afficher / masquer des colonnes" [matMenuTriggerFor]="columnMenu"
          (menuOpened)="openHideShowMenu()" (menuClosed)="closeHideShowMenu()">
          <mat-icon>view_column</mat-icon>
        </button>
        <mat-menu #columnMenu="matMenu">
          <div fxLayout="column" cdkDropList has-handle [cdkDropListDisabled]="!enableReorderColumn"
            (cdkDropListDropped)="drop($event)">
            <div class="datagrid-colum-options-item" *ngFor="let column of updatedHidden" mat-list-item cdkDrag
              (click)="$event.stopPropagation()" fxLayoutAlign="start center">
              <button *ngIf="enableHideShowColumns" mat-icon-button size="small" (click)="column.show = !column.show"
                fxLayoutAlign="start center" [style.margin-right.px]="8">
                <mat-icon color="accent" *ngIf="column.show !== false">visibility</mat-icon>
                <mat-icon *ngIf="column.show === false">visibility_off</mat-icon>
              </button>
              <button *ngIf="enableStickyColumn" mat-icon-button size="small" (click)="column.sticky = !column.sticky"
                fxLayoutAlign="start center" [style.margin-right.px]="8">
                <mat-icon color="accent" *ngIf="column.sticky === true">push_pin</mat-icon>
                <mat-icon *ngIf="column.sticky !== true">push_pin</mat-icon>
              </button>
              <span translate [translateParams]="column.labelParams" [style.margin-right.px]="8">{{column.label}}</span>
              <mat-icon *ngIf="enableReorderColumn" size="small" cdkDragHandle [style.margin]="'0 8px 0 auto'">
                open_with
              </mat-icon>
            </div>
          </div>
        </mat-menu>
      </div>

      <div *ngIf="enableExport" class="action">
        <button *ngIf="enableExport" mat-icon-button color="primary" class="icon-only" i18n-matTooltip
          matTooltip="Exporter" [disabled]="exporting" (click)="export()">
          <mat-icon>download</mat-icon>
        </button>
        <div class="loading" *ngIf="exporting" @fade>
          <mat-spinner [diameter]="32"></mat-spinner>
        </div>
      </div>

      <div *ngIf="enableRefresh" class="action">
        <button mat-icon-button color="primary" class="icon-only" i18n-matTooltip matTooltip="Recharger la page"
          [disabled]="loading" (click)="loadPage()">
          <mat-icon>sync</mat-icon>
        </button>
        <div class="loading" *ngIf="loading" @fade>
          <mat-spinner [diameter]="32"></mat-spinner>
        </div>
      </div>

      <button *ngIf="enableFullscreen" mat-icon-button color="primary" class="icon-only action"
        [matTooltip]="'fullscreen' | translate" (click)="fullscreen()">
        <mat-icon *ngIf="!isFullscreen">fullscreen</mat-icon>
        <mat-icon *ngIf="isFullscreen">fullscreen_exit</mat-icon>
      </button>
    </div>
  </div>
</ng-template>

<!-- table header actions -->
<ng-template #tableHeaderActionsTmpl let-header="header">
  <div class="cell-actions" fxLayoutAlign="end center">
    <ng-container *ngIf="header.sortable">
      <ng-container *ngTemplateOutlet="sortTemplate;context:{ column: header}">
      </ng-container>
    </ng-container>
  </div>
</ng-template>

<!-- Header template -->
<ng-template #tableHeaderTmpl let-header="header">
  <div class="cell-content" [matTooltipDisabled]="!header.tooltip" [matTooltip]="header.tooltip | translate"
    matTooltipClass="datagrid-row-tooltip" matTooltipShowDelay="500" [class.sortable]="header.sortable">
    <ng-container [ngSwitch]="(header.type === 'action' || header.searchable) ? header.type : null">

      <!-- Action -->
      <ng-container *ngSwitchCase="'action'">
        <ng-container [ngSwitch]="header.label">
          <ng-container *ngSwitchCase="'_select'">
            <mat-checkbox [checked]="false" [formControl]="selectAllControl"
              [indeterminate]="selectAllControl.value === null" [matBadgeHidden]="!selected.length"
              [matBadge]="selected.length" matBadgeOverlap="true" matBadgeSize="small">
            </mat-checkbox>
          </ng-container>
          <ng-container *ngSwitchCase="'_rownumber'">#</ng-container>
        </ng-container>
      </ng-container>

      <!-- Text -->
      <ng-container *ngSwitchCase="'text'">
        <ng-container
          *ngTemplateOutlet="tableHeaderTextTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property], type: 'text' }">
        </ng-container>
      </ng-container>

      <!-- Quill -->
      <ng-container *ngSwitchCase="'quill'">
        <ng-container
          *ngTemplateOutlet="tableHeaderTextTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property], type: 'quill' }">
        </ng-container>
      </ng-container>

      <!-- Number -->
      <ng-container *ngSwitchCase="'number'">
        <ng-container
          *ngTemplateOutlet="tableHeaderTextTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property], type: 'number' }">
        </ng-container>
      </ng-container>

      <!-- Date -->
      <ng-container *ngSwitchCase="'date'">
        <ng-container
          *ngTemplateOutlet="tableHeaderDateTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property] }">
        </ng-container>
      </ng-container>

      <!-- Select -->
      <ng-container *ngSwitchCase="'select'">
        <ng-container
          *ngTemplateOutlet="tableHeaderSelectTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property] }">
        </ng-container>
      </ng-container>

      <!-- Autocomplete -->
      <ng-container *ngSwitchCase="'autocomplete'">
        <ng-container
          *ngTemplateOutlet="tableHeaderAutocompleteTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property] }">
        </ng-container>
      </ng-container>

      <!-- Button Toggle -->
      <ng-container *ngSwitchCase="'button-toggle'">
        <ng-container
          *ngTemplateOutlet="tableHeaderSelectTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property] }">
        </ng-container>
      </ng-container>

      <!-- Checkbox -->
      <ng-container *ngSwitchCase="'checkbox'">
        <ng-container
          *ngTemplateOutlet="tableHeaderCheckboxTmpl; context: { header: header, control: search.controls[header.searchProperty || header.displayProperty || header.property] }">
        </ng-container>
      </ng-container>

      <!-- Default -->
      <div *ngSwitchDefault [style.text-align]="header.textAlign"
        [style.white-space]="header.nowrap ? 'nowrap' : undefined"
        [innerHTML]="(header.label | translate:header.labelParams) | safe:'html'"></div>

    </ng-container>
  </div>
</ng-template>

<!-- Header text template -->
<ng-template #tableHeaderTextTmpl let-header="header" let-control="control" let-type="type">
  <mat-form-field no-subscript class="text-form-field" [class.has-clear-btn]="control?.value && control?.value !== ''">
    <mat-label [innerHTML]="(header.label | translate:header.labelParams) | safe:'html'"></mat-label>
    <input [type]="type" matInput [formControl]="control" autocomplete="off">
    <button matSuffix size="small" *ngIf="control?.value && control?.value !== ''" class="clear-button" mat-icon-button
      (click)="control?.setValue(null); $event.stopPropagation()">
      <mat-icon>clear</mat-icon>
    </button>
  </mat-form-field>
</ng-template>

<!-- Header date template -->
<ng-template #tableHeaderDateTmpl let-header="header" let-control="control">

  <ng-template #datePickerPrefix>
    <button mat-icon-button [matMenuTriggerFor]="menu">
      <span [style.font-size.px]="18" [ngSwitch]="control.value.op">
        <ng-container *ngSwitchCase="'='">&equals;</ng-container>
        <ng-container *ngSwitchCase="'>'">&gt;</ng-container>
        <ng-container *ngSwitchCase="'>='">&ge;</ng-container>
        <ng-container *ngSwitchCase="'<'">&lt;</ng-container>
        <ng-container *ngSwitchCase="'<='">&le;</ng-container>
        <ng-container *ngSwitchCase="'<>'">&lt;&gt;</ng-container>
        <ng-container *ngSwitchCase="'<=>'">&le;&ge;</ng-container>
        <ng-container *ngSwitchCase="'>=<'">&le;&ge;</ng-container>
        <ng-container *ngSwitchCase="'>=<'">&le;&ge;</ng-container>
      </span>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="control.get('op').setValue('=', { emitEvent: !!control.value?.from })">
        <span value="=">&equals;</span>
        <span [style.font-size.em]="0.8" italic> Égale à</span>
      </button>
      <button mat-menu-item (click)="control.get('op').setValue('>', { emitEvent: !!control.value?.from })">
        <span value=">">&gt;</span>
        <span [style.font-size.em]="0.8" italic> Supérieur à</span>
      </button>
      <button mat-menu-item (click)="control.get('op').setValue('>=', { emitEvent: !!control.value?.from })">
        <span value=">=">&ge;</span>
        <span [style.font-size.em]="0.8" italic> Supérieur ou égale à</span>
      </button>
      <button mat-menu-item (click)="control.get('op').setValue('<', { emitEvent: !!control.value?.from })">
        <span value="<">&lt;</span>
        <span [style.font-size.em]="0.8" italic> Inférieure à</span>
      </button>
      <button mat-menu-item (click)="control.get('op').setValue('<=', { emitEvent: !!control.value?.from })">
        <span value="<=">&le;</span>
        <span [style.font-size.em]="0.8" italic> Inférieure ou égale à</span>
      </button>
      <button mat-menu-item
        (click)="control.get('op').setValue('<>', { emitEvent: !!control.value?.from && !!control.value?.to })">
        <span value="<>">&lt;&gt;</span>
        <span [style.font-size.em]="0.8" italic> Supérieure à et inférieur à</span>
      </button>
      <button mat-menu-item
        (click)="control.get('op').setValue('<=>', { emitEvent: !!control.value?.from && !!control.value?.to })">
        <span value="<=>">&le;&ge;</span>
        <span [style.font-size.em]="0.8" italic> Supérieure ou égale à et inférieur ou égale à</span>
      </button>
      <button mat-menu-item
        (click)="control.get('op').setValue('>=<', { emitEvent: !!control.value?.from && !!control.value?.to })">
        <span value=">=<">&ge;&lt;</span>
        <span [style.font-size.em]="0.8" italic> Supérieure ou égale à et inférieur à</span>
      </button>
      <button mat-menu-item
        (click)="control.get('op').setValue('>=<', { emitEvent: !!control.value?.from && !!control.value?.to })">
        <span value=">=<">&ge;&lt;</span>
        <span [style.font-size.em]="0.8" italic> Supérieure à et inférieur ou égale à</span>
      </button>
    </mat-menu>
  </ng-template>

  <ng-container *ngIf="['=', '>', '>=', '<', '<='].includes(control.value.op)" [formGroup]="control">
    <mat-form-field no-subscript>
      <mat-label [innerHTML]="(header.label | translate:header.labelParams) | safe:'html'"></mat-label>
      <input matInput formControlName="from" [matDatepicker]="picker">
      <mat-datepicker #picker></mat-datepicker>
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <div matTextPrefix>
        <ng-container *ngTemplateOutlet="datePickerPrefix"></ng-container>
      </div>
    </mat-form-field>
  </ng-container>

  <mat-form-field *ngIf="['<>', '<=>'].includes(control.value.op)">
    <mat-label [innerHTML]="(header.label | translate:header.labelParams) | safe:'html'"></mat-label>
    <mat-date-range-input [formGroup]="control" [rangePicker]="picker">
      <input matStartDate formControlName="from" placeholder="Start date">
      <input matEndDate formControlName="to" placeholder="End date">
    </mat-date-range-input>
    <mat-date-range-picker #picker></mat-date-range-picker>
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <div matTextPrefix>
      <ng-container *ngTemplateOutlet="datePickerPrefix"></ng-container>
    </div>
  </mat-form-field>

</ng-template>

<!-- Header select template -->
<ng-template #tableHeaderSelectTmpl let-header="header" let-control="control">
  <mat-form-field no-subscript>
    <mat-label [innerHTML]="(header.label | translate:header.labelParams) | safe:'html'"></mat-label>
    <mat-select [formControl]="control" [compareWith]="selectCompareWidth" [multiple]="header.multiple">
      <mat-select-trigger>
        <ng-container *ngIf="header.multiple">
          <ng-container *ngFor="let value of control?.value">
            <ng-container
              *ngTemplateOutlet="optionTmpl; context: { option: value, selected: true, hideName: header.hideHeaderName }">
            </ng-container>
          </ng-container>
        </ng-container>
        <ng-container *ngIf="!header.multiple">
          <ng-container
            *ngTemplateOutlet="optionTmpl; context: { option: control?.value, selected: true, hideName: header.hideHeaderName }">
          </ng-container>
        </ng-container>
      </mat-select-trigger>
      <mat-option *ngIf="!header.multiple" [value]="undefined">
        <mat-label color="light-shade"
          [innerHTML]="(header.label | translate:header.labelParams) | safe:'html'"></mat-label>
      </mat-option>
      <mat-option *ngFor="let option of header.options" [value]="option">
        <ng-container
          *ngTemplateOutlet="optionTmpl; context: { option: option,hideName: header.hideHeaderName }"></ng-container>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<!-- Header autocomplete template -->
<ng-template #tableHeaderAutocompleteTmpl let-header="header" let-control="control">
  <mat-form-field no-subscript>
    <mat-label [innerHTML]="(header.label | translate:header.labelParams) | safe:'html'"></mat-label>

    <input #autocompleteInput="matInput" autocomplete="off" type="text" matInput
      [formControl]="control.asynOptionsControl" [matAutocomplete]="auto"
      [style.visibility]="control.value ? 'hidden' : undefined" (focus)="control.setValue(control.value || '')">

    <ng-container *ngIf="control.value">
      <div class="autocomplete-selected-option" fxLayoutAlign="space-between center">
        <ng-container
          *ngTemplateOutlet="optionTmpl; context: { option: control.value, selected: true, hideName: header.hideHeaderName }"></ng-container>
      </div>
    </ng-container>

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="control.setValue($event.option.value)">
      <mat-option *ngIf="control.optionsAsyncLoading" [style.--diameter]="'40px'" [disabled]="true">
        <ng-container *ngTemplateOutlet="spinnerTmpl"></ng-container>
      </mat-option>
      <ng-container *ngIf="control.optionsAsync | async as options">
        <mat-option *ngFor="let option of $any(options)" [value]="option">
          <ng-container
            *ngTemplateOutlet="optionTmpl; context: { option: option, hideName: header.hideHeaderName }"></ng-container>
        </mat-option>
        <mat-option *ngIf="!control.optionsAsyncLoading && control.searchMatched > $any(options).length"
          class="full-width">
          <mat-progress-bar @fade mode="buffer" intersectionObserver
            (intersection)="control.loadMore($event)"></mat-progress-bar>
        </mat-option>
      </ng-container>
    </mat-autocomplete>

    <button matSuffix size="small" *ngIf="control?.value && control?.value !== ''" class="clear-button" mat-icon-button
      (click)="$event.stopPropagation(); control?.setValue(null); control.asynOptionsControl.setValue(null); ">
      <mat-icon>clear</mat-icon>
    </button>

  </mat-form-field>
</ng-template>

<!-- Header checkbox template -->
<ng-template #tableHeaderCheckboxTmpl let-header="header" let-control="control">
  <div fxLayoutAlign="start center" fxLayoutGap="8px">
    <ng-container [ngSwitch]="control.value">
      <button *ngSwitchCase="true" mat-icon-button class="icon-only fit-icon" size="small"
        (click)="control.setValue(false)">
        <mat-icon color="primary">check_box</mat-icon>
      </button>
      <button *ngSwitchCase="false" mat-icon-button class="icon-only fit-icon" size="small"
        (click)="control.setValue(null)">
        <mat-icon color="primary">check_box_outline_blank</mat-icon>
      </button>
      <button *ngSwitchDefault mat-icon-button class="icon-only fit-icon" size="small" (click)="control.setValue(true)">
        <mat-icon>indeterminate_check_box</mat-icon>
      </button>
    </ng-container>
    <span [innerHTML]="(header.label | translate:header.labelParams) | safe:'html'"></span>
  </div>
</ng-template>

<!-- Cell template -->
<ng-template #tableCellTmpl let-header="header" let-row="row" let-cell="cell">
  <div class="cell" [class.sticky]="header.sticky" [fxLayoutAlign]="header.textAlign + ' center'" fxLayoutGap="8px"
    [class.dialog]="header.click" (click)="cellClick($event, row, cell)" matRipple [matRippleCentered]="true"
    [matRippleDisabled]="!header.click">
    <!-- Column Content Template Display -->
    <div class="row-cell" *ngIf="header.cellTmpl && templatesByName[header.cellTmpl]" [style.color]="cell.color">
      <ng-container
        *ngTemplateOutlet="templatesByName[header.cellTmpl].template; context: { column: header, record: data[row.index], row: row, cell: cell }">
      </ng-container>
    </div>
    <!-- Column Content Component Display -->
    <div class="row-cell" *ngIf="header.cellComponent" [style.color]="cell.color">
      <ng-container
        *ngComponentOutlet="header.cellComponent; inputs: { column: header, record: data[row.index], row: row, cell: cell };" />
    </div>
    <!-- Column Display -->
    <ng-container *ngIf="!header.cellTmpl && !header.cellComponent && header.type !== 'action'">
      <mat-icon *ngIf="cell.icon && !cell.icon.svg" [fontSet]="cell.icon.fontSet" class="row-cell-icon"
        [color]="cell.icon?.color || cell.color" [style.color]="cell.icon?.color || cell.color">
        {{cell.icon.name}}
      </mat-icon>
      <mat-icon *ngIf="cell.icon && cell.icon.svg" class="row-cell-icon" [svgIcon]="cell.icon.name"
        [color]="cell.icon?.color || cell.color" [style.color]="cell.icon?.color || cell.color">
      </mat-icon>
      <div *ngIf="cell.value !== undefined" [ngSwitch]="header.type" [style.max-width.%]="100"
        [style.white-space]="header.nowrap ? 'nowrap' : undefined">
        <quill-view-html *ngSwitchCase="'quill'" [content]="cell.rawValue"></quill-view-html>
        <span *ngSwitchDefault class="row-cell-content" [attr.color]="cell.color" [style.color]="cell.color"
          [innerHTML]="cell.value" [style.text-align]="header.type === 'number' ? 'end' : header.textAlign">
        </span>
      </div>
      <span *ngIf="cell.suffix" class="row-cell-suffix" [innerHTML]="cell.suffix | safe:'html'"
        [attr.color]="cell.color" [style.color]="cell.color"></span>
    </ng-container>
    <!-- Action Display -->
    <ng-container *ngIf="header.type === 'action'" [ngSwitch]="header.label">
      <mat-checkbox *ngSwitchCase="'_select'" (change)="select(row)" (click)="$event.stopPropagation()"
        [checked]="row.selected"></mat-checkbox>
      <span *ngSwitchCase="'_rownumber'" class="row-cell-content">
        {{row.index + 1}}
      </span>
    </ng-container>

  </div>
</ng-template>

<!-- Sort template -->
<ng-template #sortTemplate let-column="column">
  <button class="sort-btn" mat-icon-button size="small" (click)="sortColumn(column)">
    <ng-container [ngSwitch]="column.sort?.dir">
      <mat-icon size="small" *ngSwitchCase="'asc'">arrow_upward</mat-icon>
      <mat-icon size="small" *ngSwitchCase="'desc'">arrow_downward</mat-icon>
      <mat-icon size="small" *ngSwitchDefault>sort</mat-icon>
    </ng-container>
    <span *ngIf="column.sort && sortedColumns.length > 1" class="sort-position">{{column.sort.position}}</span>
  </button>
</ng-template>

<ng-template #spinnerTmpl>
  <app-loader></app-loader>
</ng-template>


<ng-template #optionTmpl let-option="option" let-selected="selected" let-hideName="hideName">
  <div class="option" fxLayoutAlign="start center" fxLayoutGap="8px">
    <ng-container *ngTemplateOutlet="iconTmpl; context: { icon: option?.icon }"></ng-container>
    <mat-label class="option-name" [class.selected]="selected" *ngIf="!hideName && option?.name"
      [style.color]="(option.color ? option.color : selected ? 'var(--app-color-primary)' : undefined ) | safe:'style'"
      [innerHTML]="option.name | translate | safe:'html'" [tooltip]="option.name" tooltipOnOverflowOnly>
    </mat-label>
  </div>
</ng-template>


<ng-template #actionsTmpl let-actions="actions">
  <div *ngFor="let action of actions" class="action">

    <div *ngIf="!action.isHidden" [matTooltipDisabled]="!action.tooltip" [matTooltip]="action.tooltip!"
      [matMenuTriggerFor]="actionMenu" [matMenuTriggerData]="{ action }">

      <ng-container [ngSwitch]="action.kind">
        <button *ngSwitchCase="'icon'" mat-icon-button
          [style.color]="(action.color || 'var(--app-color-primary)') | safe:'style'" class="icon-only"
          [disabled]="action.isDisabled" (click)="execAction(action)">
          <ng-container *ngTemplateOutlet="iconTmpl; context: { icon: action.icon }"></ng-container>
        </button>
        <button *ngSwitchCase="'raised'" mat-raised-button
          [style.color]="(action.color || 'var(--app-color-primary)') | safe:'style'" class="icon-only"
          [disabled]="action.isDisabled" (click)="execAction(action)">
          <mat-label [innerHTML]="action.label | safe:'html'"></mat-label>
        </button>
        <button *ngSwitchCase="'flat'" mat-flat-button
          [style.color]="(action.color || 'var(--app-color-primary)') | safe:'style'" class="icon-only"
          [disabled]="action.isDisabled" (click)="execAction(action)">
          <mat-label [innerHTML]="action.label | safe:'html'"></mat-label>
        </button>
        <button *ngSwitchCase="'stroked'" mat-stroked-button
          [style.color]="(action.color || 'var(--app-color-primary)') | safe:'style'" class="icon-only"
          [disabled]="action.isDisabled" (click)="execAction(action)">
          <mat-label [innerHTML]="action.label | safe:'html'"></mat-label>
        </button>
        <button *ngSwitchCase="'fab'" mat-fab-button
          [style.color]="(action.color || 'var(--app-color-primary)') | safe:'style'" class="icon-only"
          [disabled]="action.isDisabled" (click)="execAction(action)">
          <mat-label [innerHTML]="action.label | safe:'html'"></mat-label>
        </button>
        <button *ngSwitchCase="'mini-fab'" mat-mini-fab-button
          [style.color]="(action.color || 'var(--app-color-primary)') | safe:'style'" class="icon-only"
          [disabled]="action.isDisabled" (click)="execAction(action)">
          <mat-label [innerHTML]="action.label | safe:'html'"></mat-label>
        </button>
      </ng-container>

    </div>

  </div>

  <mat-menu #actionMenu="matMenu">
    <ng-template matMenuContent let-action="action">
      <button *ngFor="let entry of action.menu" mat-menu-item (click)="execAction(action, entry.id)">
        <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="4px">
          <ng-container *ngTemplateOutlet="iconTmpl; context: { icon: entry.icon }"></ng-container>
          <span>{{entry.label}}</span>
        </div>
      </button>
    </ng-template>
  </mat-menu>

</ng-template>

<ng-template #iconTmpl let-icon="icon">
  <ng-container *ngIf="icon">
    <mat-icon *ngIf="!icon.svg" [style.color]="icon.color | safe:'style'" size="small">
      {{icon.name}}
    </mat-icon>
    <mat-icon *ngIf="icon.svg" [svgIcon]="icon.name" [style.color]="icon.color | safe:'style'" size="small">
    </mat-icon>
  </ng-container>
</ng-template>
