/** @format */

import { Injectable, Type } from '@angular/core';
import { ModelMapper } from 'model-mapper';
import { SesioNodeComponentCabinCallGpio } from '../../_classes/sesio-node-component/sesio-node-component.cabin-call-gpio.class';
import { SesioNodeComponent } from '../../_classes/sesio-node-component/sesio-node-component.class';
import { SesioNodeComponentDoorGpio } from '../../_classes/sesio-node-component/sesio-node-component.door-gpio.class';
import { SesioNodeComponentKind } from '../../_constants/sesio-node-component/sesio-node-component-kind';
import { CrudServiceBuild, ICrudService } from '../crud.service';
import { SesioNodeComponentStatus } from '../../_constants/sesio-node-component/sesio-node-component-status';

export interface ISesioNodeComponentFilter {
  search?: string;
  nodeId?: string;
  kinds?: SesioNodeComponentKind[];
  archived?: boolean;
  organizationIds?: string[];
}

@Injectable({
  providedIn: 'root',
})
export class SesioNodeComponentService extends ExtendsSesioNodeComponentService<
  SesioNodeComponent,
  ISesioNodeComponentFilter
>(SesioNodeComponent) {
}

export interface ISesioNodeComponentService<
  TClass extends SesioNodeComponent,
  ReqFilter extends ISesioNodeComponentFilter,
> extends ICrudService<TClass, ReqFilter> {
  kind?: SesioNodeComponentKind;

  countByStatus(filter?: ReqFilter): Promise<{ status: SesioNodeComponentStatus; count: number }[]>;

}

export function ExtendsSesioNodeComponentService<
  TClass extends SesioNodeComponent,
  ReqFilter extends ISesioNodeComponentFilter,
>(target: Type<TClass>, kind?: SesioNodeComponentKind): Type<ISesioNodeComponentService<TClass, ReqFilter>> {
  const path = kind ? `/sesio-node-component/${kind}` : '/sesio-node-component';
  class SesioNodeComponentServiceClass
    extends CrudServiceBuild<TClass, ReqFilter>(target, path)
    implements ISesioNodeComponentService<TClass, ReqFilter>
  {
    kind?: SesioNodeComponentKind = kind;

    async countByStatus(filter?: ReqFilter): Promise<{ status: SesioNodeComponentStatus; count: number }[]> {
      const path = `${this.path}/count-by-status`;
      return this.httpService.post(path, this.getFilterQuery(filter));
    }

    override buildType(data: any): TClass {
      switch (data.kind as SesioNodeComponentKind) {
        case SesioNodeComponentKind.CABIN_CALL_GPIO:
          return new ModelMapper(SesioNodeComponentCabinCallGpio).map(data) as any;
        case SesioNodeComponentKind.DOOR_GPIO:
          return new ModelMapper(SesioNodeComponentDoorGpio).map(data) as any;
        default:
          return new ModelMapper(SesioNodeComponent).map(data) as any;
      }
    }
  }
  return SesioNodeComponentServiceClass as any;
}
