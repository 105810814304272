/** @format */

import { Injectable, Type, inject } from '@angular/core';
import { each, get, filter as lfilter, map as lmap, set } from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import moment from 'moment';
import { Observable, from } from 'rxjs';
import { v4 } from 'uuid';
import { EquipmentKind } from '../../_classes/equipment-kind.class';
import {
  EquipmentSignatureIndicator,
  EquipmentSignatureIndicatorName,
} from '../../_classes/equipment-signature-indicator.class';
import { Equipment } from '../../_classes/equipment/equipment.class';
import { Elevator } from '../../_classes/equipment/equipment.elevator.class';
import { SearchItem } from '../../_classes/search.class';
import { TreeNode } from '../../_classes/tree-node.class';
import { ColumnOption } from '../../_components/datagrid/column-def.class';
import { IDatatableOptions } from '../../_components/datagrid/datatable.class';
import { EquipmentFamily } from '../../_constants/equipment-family';
import { EquipmentMode } from '../../_constants/equipment-mode';
import { EquipmentStatus, EquipmentStatusDatagridOptions } from '../../_constants/equipment-status';
import { CrudServiceBuild, ICrudService } from '../crud.service';
import { FileService } from '../file.service';

export interface IDistributionChartData {
  name: string;
  count: number;
  data: {
    name: string;
    count: number;
    data: {
      name: string;
      count: number;
    }[];
  }[];
}

export interface IStatusChartData {
  name: EquipmentFamily;
  count: number;
  data: {
    name: EquipmentStatus;
    count: number;
  }[];
}

export interface IMapInfo {
  _id: string;
  reference: string;
  coordinates: [number, number];
  status: EquipmentStatus;
  family: EquipmentFamily;
  kindId: string;
  categoryId: string;
  organizationalUnitId: string;
  realEstateStructureId: string;
}
export interface IEquipmentFilter {
  search?: SearchItem[];
  families?: EquipmentFamily[];
  statuses?: EquipmentStatus[];
  kinds?: EquipmentKind[];
  organizationalUnitId?: string;
  realEstateStructureId?: string;
  isProductionEquipment?: boolean;
  hasMode?: boolean;
  bounds?: [[number, number], [number, number]];
}

@Injectable({
  providedIn: 'root',
})
export class EquipmentService extends ExtendsEquipmentService<Equipment, IEquipmentFilter>(Equipment) {}

export interface IEquipmentService<TClass extends Equipment, ReqFilter extends IEquipmentFilter>
  extends ICrudService<TClass, ReqFilter> {
  family?: EquipmentFamily;
  fileService: FileService;
  getTreeLevel(filter?: ReqFilter): Promise<TreeNode[]>;
  getDistributionChartData(): Promise<IDistributionChartData[]>;
  getStatusChartData(): Promise<IStatusChartData[]>;
  countDocument(filter?: ReqFilter): Promise<number>;
  countByFamily(): Promise<{ family: EquipmentFamily; count: number }[]>;
  countByOrganizationFamily(): Promise<
    { organization: string; count: number; data: { family: EquipmentFamily; count: number }[] }[]
  >;
  countByStatus(filter?: ReqFilter): Promise<{ status: EquipmentStatus; count: number }[]>;
  getModes(filter?: ReqFilter): Promise<EquipmentMode[]>;
  countByMode(filter?: ReqFilter): Promise<{ mode: EquipmentMode; count: number }[]>;
  getMapInfo(filter?: ReqFilter): Promise<IMapInfo[]>;
  getSignatureIndicators(id: string, name?: EquipmentSignatureIndicatorName): Promise<EquipmentSignatureIndicator[]>;
  datatableDocument(query: IDatatableOptions, filter?: ReqFilter): Observable<any>;
  getDatagridStatusColumn(prefix?: string): ColumnOption;
  consolidate(id: string): Promise<void>;
  buildSignatureIndicators(id: string): Promise<void>;
  buildEquipments(data: any[]): TClass[];
}

export function ExtendsEquipmentService<TClass extends Equipment, ReqFilter extends IEquipmentFilter>(
  target: Type<TClass>,
  family?: EquipmentFamily,
): Type<IEquipmentService<TClass, ReqFilter>> {
  class EquipmentServiceClass
    extends CrudServiceBuild<TClass, ReqFilter>(target, '/equipment')
    implements IEquipmentService<TClass, ReqFilter>
  {
    family?: EquipmentFamily = family;
    fileService: FileService = inject(FileService);

    async getTreeLevel(filter?: ReqFilter): Promise<TreeNode[]> {
      const path = `${this.path}/tree-level`;
      return this.httpService
        .post(path, this.getFilterQuery(filter))
        .then((data) => lmap(data, (d) => new ModelMapper(TreeNode).map(d)));
    }

    async getDistributionChartData(): Promise<IDistributionChartData[]> {
      const path = `${this.path}/distribution-chart`;
      return this.httpService.get(path);
    }

    async getStatusChartData(): Promise<IStatusChartData[]> {
      const path = `${this.path}/status-chart`;
      return this.httpService.get(path);
    }

    async countDocument(filter?: ReqFilter): Promise<number> {
      const path = `${this.path}/count-document`;
      const query = this.getFilterQuery(filter);
      return this.httpService.get(path, query);
    }

    async countByFamily(): Promise<{ family: EquipmentFamily; count: number }[]> {
      const path = `${this.path}/count-by-family`;
      return this.httpService.get(path);
    }

    async countByOrganizationFamily(): Promise<
      { organization: string; count: number; data: { family: EquipmentFamily; count: number }[] }[]
    > {
      const path = `${this.path}/count-by-organization-family`;
      return this.httpService.get(path);
    }

    async countByStatus(filter?: ReqFilter): Promise<{ status: EquipmentStatus; count: number }[]> {
      const path = `${this.path}/count-by-status`;
      return this.httpService.post(path, this.getFilterQuery(filter));
    }

    async getModes(filter?: ReqFilter): Promise<EquipmentMode[]> {
      const path = `${this.path}/modes`;
      const query = this.getFilterQuery(filter);
      return this.httpService.get(path, query);
    }

    async countByMode(filter?: ReqFilter): Promise<{ mode: EquipmentMode; count: number }[]> {
      const path = `${this.path}/count-by-mode`;
      const query = this.getFilterQuery(filter);
      return this.httpService.get(path, query);
    }

    async getMapInfo(filter?: ReqFilter): Promise<IMapInfo[]> {
      const path = `${this.path}/get-map-info`;
      return this.httpService.post(path, this.getFilterQuery(filter));
    }

    async getSignatureIndicators(
      id: string,
      name?: EquipmentSignatureIndicatorName,
    ): Promise<EquipmentSignatureIndicator[]> {
      const path = `${this.path}/${id}/signature-indicators`;
      const query: any = {};
      if (name) query.name = name;
      return this.httpService
        .get(path, query)
        .then((data) => lmap(data, (d) => new ModelMapper(EquipmentSignatureIndicator).map(d)));
    }

    datatableDocument(query: IDatatableOptions, filter?: ReqFilter): Observable<any> {
      const path = `${this.path}/datatable-document`;
      return from(this.httpService.post(path, { query, filter: this.getFilterQuery(filter) }));
    }

    getDatagridStatusColumn(prefix?: string): ColumnOption {
      let path = 'status';
      if (prefix) path = `${prefix}.${path}`;
      let datePath = 'statusInfo.updatedAt';
      if (prefix) datePath = `${prefix}.${datePath}`;
      return {
        property: path,
        linkedProperties: [path, datePath],
        label: $localize`Statut`,
        searchable: true,
        sortable: true,
        type: 'select',
        hideName: true,
        suffix: (record: any) => (get(record, datePath) ? moment(get(record, datePath)).format('L LT') : ''),
        options: EquipmentStatusDatagridOptions,
      };
    }

    async consolidate(id: string): Promise<void> {
      const path = `${this.path}/consolidate/${id}`;
      return this.httpService.patch(path);
    }

    async buildSignatureIndicators(id: string): Promise<void> {
      const path = `${this.path}/build-signature-indicators/${id}`;
      return this.httpService.patch(path);
    }

    buildEquipments(data: any[]): TClass[] {
      return lmap(data, (d) => this.buildType(d));
    }

    override buildType(data: any): TClass {
      switch (data?.kind?.category?.family as EquipmentFamily) {
        case EquipmentFamily.ELEVATOR:
          return new ModelMapper(Elevator).map(data) as any;
        default:
          return new ModelMapper(Equipment).map(data) as any;
      }
    }

    override async processData(data: any, action: 'create' | 'update'): Promise<any> {
      let s3Path = get(data, 's3Path');
      if (!s3Path && action === 'create') set(data, 's3Path', (s3Path = v4()));
      if (!s3Path) throw new Error(`missing s3Path`);
      data.family = this.family;
      if (!data.family) data.family = get(data, 'family', get(data, 'kind.category.family'));
      if (!data.family) throw new Error(`missing family`);
      const promises: Promise<any>[] = [];
      promises.push(this.httpService.processEmbeddedFiles(get(data, 'documents'), `${s3Path}/documents`));
      promises.push(this.httpService.processFiles(get(data, 'pictures'), `${s3Path}/picture_library`));
      each(get(data, 'consumptions'), (consumption: any) =>
        promises.push(
          this.httpService.processEmbeddedFiles(consumption.entries, `${s3Path}/consumptions/${consumption.year}`),
        ),
      );
      // set(data, 'assignments', lmap(get(data, 'assignments'), '_id'));
      await Promise.all(promises);
      return super.processData(data, action);
    }

    override getFilterQuery(filter?: ReqFilter, fields?: string | string[], sorts?: string): any {
      const filterQuery = super.getFilterQuery(filter, fields, sorts);
      if (this.family) filterQuery.family = this.family;
      return filterQuery;
    }

    override setFilterQueryEntry(filter: ReqFilter, query: any, key: string, value: any): void {
      switch (key) {
        case 'kinds':
          set(
            query,
            key,
            lmap(
              lfilter(value, (kind) => !!kind),
              (kind) => (kind instanceof EquipmentKind ? kind._id : kind),
            ),
          );
          break;
        default:
          super.setFilterQueryEntry(filter, query, key, value);
      }
    }
  }

  return EquipmentServiceClass as any;
}
