/** @format */

import { Component, inject, Input, ViewChild } from '@angular/core';
import humanizeDuration from 'humanize-duration';
import { get, isEqual, isNil, round } from 'lodash-es';
import moment from 'moment';
import { Equipment } from '../../../_classes/equipment/equipment.class';
import { Extremes } from '../../../_classes/extremes.class';
import { EquipmentDoorNameDatagridOptions } from '../../../_constants/equipment-door/equipment-door-name';
import { EquipmentDoorStateDatagridOptions } from '../../../_constants/equipment-door/equipment-door-state';
import { EquipmentSignatureStatusDatagridOptions } from '../../../_constants/equipment-signature-status';
import { EquipmentStatusDatagridOptions } from '../../../_constants/equipment-status';
import { getFloorName } from '../../../_helpers/equipment.helper';
import { DoorStateDataService } from '../../../_services/door-state-data.service';
import { DatagridComponent, IDatagridOptions } from '../../datagrid/datagrid.component';
import { IDatatableOptions } from '../../datagrid/datatable.class';

@Component({
  selector: 'app-door-state-datagrid',
  templateUrl: './door-state-datagrid.component.html',
  styleUrls: ['./door-state-datagrid.component.scss'],
})
export class DoorStateDatagridComponent {
  @Input('equipment')
  set setEquipment(equipment: Equipment) {
    if (isEqual(equipment, this.equipment)) return;
    this.equipment = equipment;
    if (this.equipment) this.loadPage();
  }
  equipment: Equipment;

  public setExtremes(extremes: Extremes): void {
    console.log('setExtremes', extremes);
    if (isEqual(extremes, this.extremes)) return;
    this.extremes = extremes;
    this.loadPage();
  }
  extremes?: Extremes;

  options: IDatagridOptions = {
    pagination: { default: 0, options: [10, 20, 50, 100] },
    service: (query: IDatatableOptions) =>
      this.doorStateDataService.datatable(query, {
        equipmentId: this.equipment!._id,
        from: this.extremes?.start,
        to: this.extremes?.end,
      }),
    columns: [
      {
        property: 'equipmentSignatureStatus',
        label: $localize`Signature`,
        type: 'select',
        options: EquipmentSignatureStatusDatagridOptions,
        maxWidth: 300,
      },
      {
        property: 'equipmentStatus',
        label: $localize`Équipement`,
        type: 'select',
        options: EquipmentStatusDatagridOptions,
        maxWidth: 300,
      },
      {
        property: 'doorName',
        label: $localize`Porte`,
        type: 'select',
        options: EquipmentDoorNameDatagridOptions,
        searchable: true,
        sortable: true,
      },
      {
        property: 'state',
        label: $localize`État de la porte`,
        type: 'select',
        options: EquipmentDoorStateDatagridOptions,
        searchable: true,
        sortable: true,
        maxWidth: 300,
      },
      {
        property: 'start',
        label: $localize`Date début`,
        type: 'date',
        format: 'L LTS',
        searchable: true,
        sortable: true,
        maxWidth: 250,
      },
      {
        property: 'end',
        label: $localize`Date de fin`,
        type: 'date',
        format: 'L LTS',
        searchable: true,
        sortable: true,
        maxWidth: 250,
      },
      {
        property: 'duration',
        label: $localize`Durée`,
        displayWith: (record: any) => {
          if (isNil(record.duration)) return '-';
          return humanizeDuration(record.duration, { language: 'fr', maxDecimalPoints: 0, largest: 1 });
        },
      },
      {
        property: 'distance',
        label: $localize`Niveau`,
        displayWith: (record: any) => {
          const floors = get(this.equipment, 'metadata.floors');
          if (!floors) return ``;
          const distance = get(record, 'distance');
          if (!distance) return ``;
          const value = get(record, 'distance.value');
          if (isNil(value)) return '-';
          const date = get(record, 'distance.date');
          if (!date) return '-';
          return $localize`<div>${getFloorName(floors, value) || '?'}</div><span light>${round(value / 100, 2)} m à ${moment(date).format('L LT')}</span>`;
        },
        searchable: true,
        sortable: true,
      },
    ],
    sortedColumns: [{ column: 4, dir: 'desc' }],
  };
  @ViewChild('datagrid', { read: DatagridComponent, static: false })
  datagrid: DatagridComponent;

  private doorStateDataService = inject(DoorStateDataService);

  private loadPage() {
    if (this.datagrid) this.datagrid.loadPage();
  }
}
