/** @format */

import { Injectable } from '@angular/core';
import { get } from 'lodash-es';
import { DurationInputArg2, Moment } from 'moment';
import { HttpService } from './http.service';

export interface ISpeedChartData {
  timestamp: number;
  min: number;
  max: number;
  avg: number;
}

export type DrilldowGrouping = 'year' | 'month' | 'day' | 'hour' | 'minute' | 'second' | null;

@Injectable({
  providedIn: 'root',
})
export class SensorDataService {
  constructor(private httpService: HttpService) {}

  async getFloorChartData(equipmentId: string, from?: Moment, to?: Moment): Promise<[[number, number]]> {
    const path = '/sensor-data/floor-chart-data';
    const query = { equipmentId, from: from?.toISOString(), to: to?.toISOString() };
    return this.httpService.get(path, query);
  }

  async getSpeedChartData(equipmentId: string, from?: Moment, to?: Moment): Promise<ISpeedChartData[]> {
    const path = '/sensor-data/speed-chart-data';
    const query = { equipmentId, from: from?.clone().utc(true).toISOString(), to: to?.clone().utc(true).toISOString() };
    return this.httpService.get(path, query);
  }

  async getFloorAttendance(
    equipmentId: string,
    from?: Moment,
    to?: Moment
  ): Promise<{ name: string; shortName: string; index: number; count: number }[]> {
    const path = `/sensor-data/floor-attendance`;
    const query = { equipmentId, from: from?.clone().utc(true).toISOString(), to: to?.clone().utc(true).toISOString() };
    return this.httpService.get(path, query);
  }

  async getMouvementCount(
    equipmentId: string,
    from: Moment,
    to: Moment,
    grouping?: DrilldowGrouping
  ): Promise<{ name: any; count: number }[]> {
    const path = `/sensor-data/mouvement-count`;
    const query = {
      equipmentId,
      from: from.clone().utc(true).toISOString(),
      to: to.clone().utc(true).toISOString(),
      grouping,
    };
    return this.httpService.get(path, query);
  }

  async getSpeedAverage(equipmentId: string, from?: Moment, to?: Moment): Promise<number | null> {
    const path = `/sensor-data/speed-average`;
    const query = {
      equipmentId,
      from: from?.clone().utc(true)?.toISOString(),
      to: to?.clone().utc(true)?.toISOString(),
    };
    return get(await this.httpService.get(path, query), 'value', null);
  }

  async getBoilerRoomChartData(
    from?: Moment,
    to?: Moment
  ): Promise<{
    dju: [number, number][];
    outsideTemperature: [number, number, number, number, number][];
    indoorTemperature: [number, number][];
    indoorHumidity: [number, number][];
    ecsStart: [number, number, number, number, number][];
    heatingStart: [number, number][];
    primaryEnergy: [number, number][];
    consumption: [number, number][];
  }> {
    const path = `/sensor-data/boiler-room-chart-data`;
    const query = { from: from?.clone().utc(true)?.toISOString(), to: to?.clone().utc(true)?.toISOString() };
    return await this.httpService.get(path, query);
  }

  async getIndoorInfo(from?: Moment, to?: Moment): Promise<any[]> {
    const path = `/sensor-data/indoor-info`;
    const query = { from: from?.clone().utc(true)?.toISOString(), to: to?.clone().utc(true)?.toISOString() };
    return await this.httpService.get(path, query);
  }

  async getKinds(kinds: string[], from?: Moment, to?: Moment): Promise<any> {
    const path = `/sensor-data/kinds`;
    const query = { kinds, from: from?.clone().utc(true)?.toISOString(), to: to?.clone().utc(true)?.toISOString() };
    return await this.httpService.get(path, query);
  }

  async weartherChart(
    from?: Moment,
    to?: Moment
  ): Promise<{
    temperatures: Array<{ x: number; y: number; weatherCode: number }>;
    precipitations: Array<{ x: number; y: number }>;
    pressures: Array<{ x: number; y: number }>;
    winds: Array<{ x: number; y: number }>;
    windgusts: Array<{ x: number; y: number }>;
  }> {
    const path = `/sensor-data/weather-chart`;
    const query: any = {};
    if (from) query.from = from.toISOString();
    if (to) query.to = to.toISOString();
    return await this.httpService.get(path, query);
  }
}
