/** @format */

import { map, values } from 'lodash-es';

export enum ConnectivityStatus {
  CONNECTED = 'connected',
  DISCONNECTED = 'disconnected',
  ND = 'nd',
}

export const ConnectivityStatusColorName: { [key: string]: string } = {
  [ConnectivityStatus.CONNECTED]: 'success',
  [ConnectivityStatus.DISCONNECTED]: 'noflow',
  [ConnectivityStatus.ND]: 'nd',
};

export const ConnectivityStatusColor: { [key: string]: string } = {
  [ConnectivityStatus.CONNECTED]: `var(--app-color-${ConnectivityStatusColorName[ConnectivityStatus.CONNECTED]})`,
  [ConnectivityStatus.DISCONNECTED]: `var(--app-color-${ConnectivityStatusColorName[ConnectivityStatus.DISCONNECTED]})`,
  [ConnectivityStatus.ND]: `var(--app-color-${ConnectivityStatusColorName[ConnectivityStatus.ND]})`,
};

export const ConnectivityStatusIcon: { [key: string]: string } = {
  [ConnectivityStatus.CONNECTED]: 'check_circle',
  [ConnectivityStatus.DISCONNECTED]: 'error',
  [ConnectivityStatus.ND]: 'help_outline',
};

export const ConnectivityStatusName: { [key: string]: string } = {
  [ConnectivityStatus.CONNECTED]: $localize`Connecté`,
  [ConnectivityStatus.DISCONNECTED]: $localize`Déconnecté`,
  [ConnectivityStatus.ND]: $localize`Non défini`,
};

export const ConnectivityStatusOptions = map(values(ConnectivityStatus), (value) => ({
  value,
  name: ConnectivityStatusName[value],
  prefixIcon: ConnectivityStatusIcon[value],
  color: ConnectivityStatusColor[value],
}));

export const ConnectivityStatusChartOptions = map(values(ConnectivityStatus), (value) => ({
  value,
  name: ConnectivityStatusName[value],
  icon: ConnectivityStatusIcon[value],
  color: ConnectivityStatusColor[value],
}));

export const ConnectivityStatusDatagridOptions = map(values(ConnectivityStatus), (value) => ({
  value,
  name: ConnectivityStatusName[value],
  icon: { name: ConnectivityStatusIcon[value], color: ConnectivityStatusColor[value] },
}));
