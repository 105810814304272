/** @format */

import { map, values } from 'lodash-es';

export enum SesioNodeStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  NOFLOW = 'noflow',
  UNKNOWN = 'unknown',
  ND = 'nd',
}

export const SesioNodeStatusColorName: { [key: string]: string } = {
  [SesioNodeStatus.ONLINE]: 'success',
  [SesioNodeStatus.OFFLINE]: 'out-of-order',
  [SesioNodeStatus.NOFLOW]: 'noflow',
  [SesioNodeStatus.UNKNOWN]: 'medium',
  [SesioNodeStatus.ND]: 'nd',
};

export const SesioNodeStatusColor: { [key: string]: string } = {
  [SesioNodeStatus.ONLINE]: `var(--app-color-${SesioNodeStatusColorName[SesioNodeStatus.ONLINE]})`,
  [SesioNodeStatus.OFFLINE]: `var(--app-color-${SesioNodeStatusColorName[SesioNodeStatus.OFFLINE]})`,
  [SesioNodeStatus.NOFLOW]: `var(--app-color-${SesioNodeStatusColorName[SesioNodeStatus.NOFLOW]})`,
  [SesioNodeStatus.UNKNOWN]: `var(--app-color-${SesioNodeStatusColorName[SesioNodeStatus.UNKNOWN]})`,
  [SesioNodeStatus.ND]: `var(--app-color-${SesioNodeStatusColorName[SesioNodeStatus.ND]})`,
};

export const SesioNodeStatusIcon: { [key: string]: string } = {
  [SesioNodeStatus.ONLINE]: 'check_circle',
  [SesioNodeStatus.OFFLINE]: 'error',
  [SesioNodeStatus.NOFLOW]: 'link_off',
  [SesioNodeStatus.UNKNOWN]: 'info',
  [SesioNodeStatus.ND]: 'help_outline',
};

export const SesioNodeStatusName: { [key: string]: string } = {
  [SesioNodeStatus.ONLINE]: $localize`En fonctionnement`,
  [SesioNodeStatus.OFFLINE]: $localize`En panne`,
  [SesioNodeStatus.NOFLOW]: $localize`Déconnecté`,
  [SesioNodeStatus.UNKNOWN]: $localize`Inconnu`,
  [SesioNodeStatus.ND]: $localize`Non défini`,
};

export const SesioNodeStatusOptions = map(values(SesioNodeStatus), (value) => ({
  value,
  name: SesioNodeStatusName[value],
  prefixIcon: SesioNodeStatusIcon[value],
  color: SesioNodeStatusColor[value],
}));

export const SesioNodeStatusDatagridOptions = map(values(SesioNodeStatus), (value) => ({
  value,
  name: SesioNodeStatusName[value],
  icon: { name: SesioNodeStatusIcon[value], color: SesioNodeStatusColor[value] },
}));
