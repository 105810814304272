/** @format */

import { PropertyMap } from 'model-mapper';
import { ConnectivityStatus } from '../../_constants/connectivity-status';
import { GpioCircuitMode } from '../../_constants/gpio/gpio-circuit-mode';
import { GpioDirection } from '../../_constants/gpio/gpio-direction';
import { GpioGroup } from '../../_constants/gpio/gpio-group';
import { GpioKind } from '../../_constants/gpio/gpio-kind';
import { SesioNodeHat } from '../../_constants/sesio-node/sesio-node-hat';
import { SesioNodeKind } from '../../_constants/sesio-node/sesio-node-kind';
import { SesioNodeStatus } from '../../_constants/sesio-node/sesio-node-status';
import { embeddedSerialize } from '../../_helpers/class.helper';
import { Archived } from '../archived.class';
import { EmbeddedCustomerAccount } from '../customer-account.embedded.class';
import { FieldUpdate } from '../field-update.class';

export class SesioNodeGpioPin {
  @PropertyMap()
  block: string;

  @PropertyMap()
  signal: number;

  @PropertyMap()
  physicalNumber: number;

  @PropertyMap()
  gpioNumber: number;

  @PropertyMap()
  circuitMode: GpioCircuitMode;

  @PropertyMap()
  direction: GpioDirection;

  @PropertyMap()
  duration: number; // in seconds

  @PropertyMap()
  kind: GpioKind;

  @PropertyMap()
  group: GpioGroup;

  @PropertyMap()
  meaning: string;
}

export class SesioNodeConnectivity {
  @PropertyMap()
  status: ConnectivityStatus;

  @PropertyMap()
  statusInfo: FieldUpdate<ConnectivityStatus>;

  @PropertyMap({ type: Date })
  statusDate: Date;

  @PropertyMap()
  ipAddress?: string;

  @PropertyMap()
  disconnectReason?: string;

  @PropertyMap()
  clientInitiatedDisconnect?: boolean;
}

export class SesioNode {
  @PropertyMap()
  _id: string;

  @PropertyMap({ type: EmbeddedCustomerAccount, serialize: embeddedSerialize })
  customerAccount?: EmbeddedCustomerAccount;

  @PropertyMap()
  organization?: string;

  @PropertyMap()
  reference: string;

  @PropertyMap()
  version: string;

  @PropertyMap()
  label?: string;

  @PropertyMap()
  customerAccountIdentifier?: string;

  @PropertyMap()
  kind: SesioNodeKind;

  @PropertyMap()
  hat: SesioNodeHat;

  @PropertyMap()
  status: SesioNodeStatus;

  @PropertyMap({ type: FieldUpdate })
  statusInfo: FieldUpdate<SesioNodeStatus>;

  @PropertyMap({ type: Date })
  lastStatusReportDate: Date;

  @PropertyMap({ type: SesioNodeConnectivity })
  connectivity: SesioNodeConnectivity;

  @PropertyMap({ type: [SesioNodeGpioPin] })
  gpio: SesioNodeGpioPin[];

  get telemetryTopic(): string | undefined {
    if (!this.reference) return undefined;
    return `devices/${this.reference}/telemetry`;
  }

  get identifyTopic(): string | undefined {
    if (!this.reference) return undefined;
    return `devices/${this.reference}/status/command`;
  }

  @PropertyMap({ type: Archived })
  __archived: Archived;
}
