/** @format */

import { deburr, kebabCase, toLower, trim } from 'lodash-es';

export function buildSearchString(value: string): string {
  return deburr(toLower(trim(value)));
}

export function buildSlugString(value: string): string {
  return kebabCase(deburr(trim(value)));
}

export function isObjectId(value: any): boolean {
  return typeof value === 'string' && /^[0-9a-fA-F]{24}$/.test(value);
}
