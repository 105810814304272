/** @format */

import { Injectable } from '@angular/core';
import { includes, merge } from 'lodash-es';
import { filter, Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { v4 } from 'uuid';
import { environment } from '../../../environments/environment';
import { SesioNode } from '../../_classes/sesio-node/sesio-node.class';
import { hasLiveTopic, SesioNodeComponentKind } from '../../_constants/sesio-node-component/sesio-node-component-kind';
import { SessionData } from '../../app.session.data';
import { MqttService } from './mqtt.service';
import { ISesioPayload, SesioPayloadOrigin } from './node-mqtt.interface';

export type TopicName = 'debug' | 'live' | 'data';

type Component = {
  _id: string;
  reference: string;
  kind: SesioNodeComponentKind;
  node: { reference: string };
};

@Injectable({
  providedIn: 'root',
})
export class NodeMqttService extends MqttService {
  public getDebugTopicSubscription<T extends ISesioPayload>(component: Component): Observable<T> {
    if (!component) return of();
    const topic = `devices/${component.node.reference}/${component.kind}/debug`;
    return this.getTopicSubscription(topic).pipe(filter((data: T) => data?.component === component.reference));
  }

  public getLiveTopicSubscription<T extends ISesioPayload>(component: Component | null | undefined): Observable<T> {
    if (!component) return of();
    const topic = `devices/${component.node.reference}/${component.kind}/live`;
    return this.getTopicSubscription(topic).pipe(filter((data: T) => data?.component === component.reference));
  }

  public getDataTopicSubscription<T extends ISesioPayload>(component: Component | null | undefined): Observable<T> {
    if (!component) return of();
    const topic = `devices/${component.node.reference}/${component.kind}/data`;
    return this.getTopicSubscription(topic).pipe(filter((data: T) => data?.component === component.reference));
  }

  public getReadTopicSubscription<T extends ISesioPayload>(component: Component | null | undefined): Observable<T> {
    if (!component) return of();
    if (includes(hasLiveTopic, component.kind)) return this.getLiveTopicSubscription(component);
    return this.getDataTopicSubscription(component);
  }

  public  publishDebugAction(component: Component): void {
    if (!component) return;
    const topic = `devices/${component.node.reference}/${component.kind}/command`;
    const msg = merge(this.buildPayload(topic, component.node.reference, component.reference), { action: 'debug' });
    return this.publishToTopic(topic, msg);
  }

  public  publishLiveAction(component: Component): void {
    if (!component) return;
    const topic = `devices/${component.node.reference}/${component.kind}/command`;
    const msg = merge(this.buildPayload(topic, component.node.reference, component.reference), { action: 'live' });
    return this.publishToTopic(topic, msg);
  }

  public async identify(node: SesioNode): Promise<void> {
    if (!node) return;
    const topic = `devices/${node.reference}/status/command`;
    const msg = merge(this.buildPayload(topic, node.reference), { action: 'identify' });
    return this.publishToTopic(topic, msg);
  }

  private buildPayload(topic: string, device: string): ISesioPayload;
  private buildPayload(topic: string, device: string, component: string): ISesioPayload;
  private buildPayload(topic: string, device: string, component?: string): ISesioPayload {
    if (!component) component = 'device';
    const id = v4();
    const env = { ENV: environment.env, AWS_REGION: environment.region };
    const origin: SesioPayloadOrigin = {
      kind: 'user',
      functionName: 'webapp',
      info: { userId: SessionData.user?._id, email: SessionData.user?.email },
    };
    return { 'Symbol(topic)': topic, uuid: this.uuid, env, origin, id, device, component, time: Date.now() };
  }
}
