/** @format */

import { Component, inject, Input, ViewChild } from '@angular/core';
import humanizeDuration from 'humanize-duration';
import { get, isEqual, isNil, join, map, mean, round } from 'lodash-es';
import { Equipment } from '../../../_classes/equipment/equipment.class';
import { ElevatorFloor } from '../../../_classes/equipment/equipment.elevator.class';
import { Extremes } from '../../../_classes/extremes.class';
import { EquipmentStatusDatagridOptions } from '../../../_constants/equipment-status';
import { getFloorName } from '../../../_helpers/equipment.helper';
import { MouvementDataService } from '../../../_services/mouvement-data.service';
import { DatagridComponent, IDatagridOptions } from '../../datagrid/datagrid.component';
import { IDatatableOptions } from '../../datagrid/datatable.class';

@Component({
  selector: 'app-mouvement-data-datagrid',
  templateUrl: './mouvement-data-datagrid.component.html',
  styleUrls: ['./mouvement-data-datagrid.component.scss'],
})
export class MouvementDataDatagridComponent {
  @Input('equipment')
  set setEquipment(equipment: Equipment) {
    if (isEqual(equipment, this.equipment)) return;
    this.equipment = equipment;
    if (this.equipment) this.loadPage();
  }
  equipment: Equipment;

  public setExtremes(extremes: Extremes): void {
    console.log('setExtremes', extremes);
    if (isEqual(extremes, this.extremes)) return;
    this.extremes = extremes;
    this.loadPage();
  }
  extremes?: Extremes;

  options: IDatagridOptions = {
    pagination: { default: 0, options: [10, 20, 50, 100] },
    service: (query: IDatatableOptions) =>
      this.mouvementDataService.datatable(query, {
        equipmentId: this.equipment!._id,
        from: this.extremes?.start,
        to: this.extremes?.end,
      }),
    columns: [
      {
        property: 'equipmentStatus',
        label: $localize`Équipement`,
        type: 'select',
        options: EquipmentStatusDatagridOptions,
        maxWidth: 300,
      },
      {
        property: 'start',
        label: $localize`Date début`,
        type: 'date',
        format: 'L LTS',
        searchable: true,
        sortable: true,
        maxWidth: 250,
      },
      {
        property: 'end',
        label: $localize`Date de fin`,
        type: 'date',
        format: 'L LTS',
        searchable: true,
        sortable: true,
        maxWidth: 250,
      },
      {
        property: 'duration',
        label: $localize`Durée`,
        displayWith: (record: any) => {
          const start = record.start ? new Date(record.start).valueOf() : null;
          const end = record.end ? new Date(record.end).valueOf() : null;
          if (!start || !end) return '-';
          return humanizeDuration(end - start, { language: 'fr', maxDecimalPoints: 0, largest: 1 });
        },
      },
      {
        property: 'startDistance',
        label: $localize`Niveau de départ`,
        displayWith: (record: any) => {
          const floors: ElevatorFloor[] = get(this.equipment, 'metadata.floors', []);
          if (!floors) return ``;
          const to = get(record, 'startDistance');
          if (isNil(to)) return '-';
          return `<div>${getFloorName(floors, to) || '?'}</div><span light>${round(to / 100, 2)} m</span>`;
        },
        searchable: true,
        sortable: true,
      },
      {
        property: 'endDistance',
        label: $localize`Niveau d'arrivé`,
        displayWith: (record: any) => {
          const floors = get(this.equipment, 'metadata.floors');
          if (!floors) return ``;
          const end = get(record, 'endDistance');
          if (isNil(end)) return '-';
          return `<div>${getFloorName(floors, end) || '?'}</div><span light>${round(end / 100, 2)} m</span>`;
        },
        searchable: true,
        sortable: true,
      },
      {
        property: 'distance',
        linkedProperties: ['startDistance', 'endDistance'],
        label: $localize`Distance parcourue`,
        displayWith: (record: any) => {
          const from = get(record, 'startDistance');
          if (isNil(from)) return '-';
          const to = get(record, 'endDistance');
          if (isNil(to)) return '-';
          return `${round(Math.abs(to - from) / 100, 2)} m`;
        },
      },
      {
        property: 'speeds',
        label: $localize`Vitesses`,
        displayWith: (record) => `
            <div>${round(mean(map(get(record, 'speeds'), 'speed')), 2)} ms</div>
            <span style="font-size: 0.8em;" light>(${join(
              map(get(record, 'speeds'), (speed: any) => `${round(speed.speed, 2)} ms`),
              ', ',
            )})</span>
            `,
        searchable: true,
        sortable: true,
        maxWidth: 500,
      },
    ],
    sortedColumns: [{ column: 1, dir: 'desc' }],
  };
  @ViewChild('datagrid', { read: DatagridComponent, static: false })
  datagrid: DatagridComponent;

  private mouvementDataService = inject(MouvementDataService);

  private loadPage() {
    if (this.datagrid) this.datagrid.loadPage();
  }
}
