/** @format */

import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from './confirm-dialog.component';
import { ConfirmDialogModule } from './confirm-dialog.module';

@Injectable({
  providedIn: 'root',
})
export class ConfirmDialogService {
  private dialog: MatDialog = inject(MatDialog);
  private dialogRef: MatDialogRef<ConfirmDialogComponent, any> | null;
  confirm(data?: { title?: string; message?: string; ok?: string; cancel?: string }): Promise<boolean | null> {
    return new Promise((resolve) => {
      if (this.dialogRef) return resolve(null);
      this.dialogRef = this.dialog.open(ConfirmDialogComponent, {
        panelClass: ['dialog-no-padding'],
        data,
        minWidth: '400px',
      });
      this.dialogRef.afterClosed().subscribe((data) => {
        this.dialogRef = null;
        if (data === true) return resolve(true);
        if (data === false) return resolve(false);
        resolve(null);
      });
    });
  }
}
