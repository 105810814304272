/** @format */

import { formatNumber } from '@angular/common';
import { ValidatorFn } from '@angular/forms';
import { filter, find, get, includes, isNil, map } from 'lodash-es';
import { PropertyMap } from 'model-mapper';
import * as moment from 'moment';
import { ComponentType } from 'ngx-toastr';
import * as striptags from 'striptags';
import { DatagridComponent } from './datagrid.component';
import { IRecord } from './datasource';
import { IIcon } from './icon.class';

export interface ICellData {
  value?: string | string[] | null;
  color: string | null;
  bgColor: string | null;
  icon: IIcon | null;
  content?: string;
  suffix?: string;
}

export interface IColumnOptionLabelValue {
  value: any;
  name: string;
  color?: string;
  bgColor?: string;
  icon?: IIcon;
}
export interface IColumnOptionIconValue {
  value: any;
  icon: IIcon;
  color?: string;
  name?: string;
}

export type ColumnOptionValue = IColumnOptionLabelValue | IColumnOptionIconValue;

export type SortDir = 'asc' | 'desc';

interface IColumnOption<Record extends IRecord = IRecord> {
  property: string;
  label: string;
  labelParams?: { [key: string]: any };
  tooltip?: string;
  translateValue?: boolean;
  textAlign?: 'start' | 'center' | 'end';
  color?: ((record: Record) => string) | string;
  bgColor?: ((record: Record) => string) | string;
  prefixIcon?: ((record: Record) => IIcon | string) | string;
  suffix?: ((record: Record) => string) | string;
  searchable?: boolean;
  sortable?: boolean;
  show?: boolean;
  maxWidth?: number;
  paddingLeft?: string;
  sticky?: boolean;
  type?: string;
  displayProperty?: string;
  searchProperty?: string;
  sortProperty?: string;
  displayWith?: (record: Record) => string;
  cellTmpl?: string;
  cellComponent?: ComponentType<any>;
  exportable?: boolean;
  contentExport?: (column: ColumnDef<Record>, record: Record) => string;
  splitExport?: (record: Record) => { name: string; value: any }[];
  click?: (record: Record, datagrid: DatagridComponent<Record>) => Promise<void>;
  linkedProperties?: string[];
  metadata?: any;

  // TODO
  searchValidators?: ValidatorFn[];
}

export interface IHiddenColumnOption {
  property: string;
  hidden: true;
  sortable?: boolean;
}

export interface ITextColumnOption extends IColumnOption {
  type?: 'text';
  nowrap?: boolean;
  strict?:boolean
}

export interface IQuillColumnOption extends IColumnOption {
  type: 'quill';
}

export interface INumberColumnOption extends IColumnOption {
  type: 'number';
  precision?: number;
}

export interface IDateColumnOption extends IColumnOption {
  type: 'date';
  fromNow?: boolean;
  format?: string;
}

export interface ISelectColumnOption extends IColumnOption {
  type: 'select';
  options: ColumnOptionValue[];
  multiple?: boolean;
  hideHeaderName?: boolean;
  hideName?: boolean;
  default?: ColumnOptionValue;
}

export interface IAutocompleteColumnOption extends IColumnOption {
  type: 'autocomplete';
  options: ColumnOptionValue[];
}

export interface IAutocompleteAsyncColumnOption extends IColumnOption {
  type: 'autocomplete';
  optionsAsync: (
    limit: number,
    skip: number,
    value: string,
  ) => Promise<{ data: ColumnOptionValue[]; matchCount: number }>;
  startWith?: string;
}

export interface IToggleButtonColumnOption extends IColumnOption {
  type: 'button-toggle';
  options: ColumnOptionValue[];
}

export interface ICheckboxColumnOption extends IColumnOption {
  type: 'checkbox';
}

export type ColumnOption =
  | IHiddenColumnOption
  | ITextColumnOption
  | IQuillColumnOption
  | INumberColumnOption
  | IDateColumnOption
  | ISelectColumnOption
  | IAutocompleteColumnOption
  | IAutocompleteAsyncColumnOption
  | IToggleButtonColumnOption
  | ICheckboxColumnOption;

export class Option {
  @PropertyMap()
  public value: any;

  @PropertyMap()
  public name?: string;

  @PropertyMap()
  public color?: string;

  @PropertyMap()
  public bgColor?: string;

  @PropertyMap()
  public icon?: IIcon;
}

export class Sort {
  static build({ dir, column }: { dir?: SortDir; column: number }): Sort {
    const sort = new Sort();
    sort.dir = dir || 'asc';
    sort.position = column;
    return sort;
  }

  @PropertyMap()
  public dir!: SortDir;

  @PropertyMap()
  public position!: number;
}

export class ColumnDef<Record extends IRecord = IRecord> {
  @PropertyMap({ default: 'text' })
  public type!: string;

  @PropertyMap()
  public property!: string;

  @PropertyMap({ default: null })
  public displayProperty!: string;

  @PropertyMap({ default: null })
  public searchProperty!: string;

  @PropertyMap({ default: null })
  public sortProperty!: string;

  @PropertyMap({ default: [] })
  public linkedProperties!: string[];

  @PropertyMap()
  public label!: string;

  @PropertyMap()
  public labelParams!: string;

  @PropertyMap()
  public tooltip!: string;

  @PropertyMap({ default: false })
  public translateValue!: boolean;

  @PropertyMap({ default: 'start' })
  public textAlign!: 'start' | 'center' | 'end';

  @PropertyMap()
  public searchable!: boolean;

  @PropertyMap()
  public sortable!: boolean;

  @PropertyMap({ default: true })
  public show!: boolean;

  @PropertyMap({ default: false })
  public hidden!: boolean;

  @PropertyMap({ default: null })
  public maxWidth!: number;

  @PropertyMap({ default: null })
  public paddingLeft!: string;

  @PropertyMap({ default: false })
  public nowrap: boolean;

  @PropertyMap({ default: true })
  public strict: boolean;

  @PropertyMap({ default: false })
  public sticky!: boolean;

  @PropertyMap({ default: null })
  public prefixIcon!: ((record: Record) => IIcon | string) | string;

  @PropertyMap()
  public suffix!: ((record: Record) => string) | string;

  @PropertyMap({ default: null })
  public color!: ((record: Record) => string) | string;

  @PropertyMap({ default: null })
  public bgColor!: ((record: Record) => string) | string;

  @PropertyMap({ default: null })
  public click!: (record: Record, datagrid: DatagridComponent<Record>) => Promise<void>;

  @PropertyMap({ default: null })
  public displayWith!: (record: Record) => string;

  @PropertyMap({ default: null })
  public cellTmpl?: string;

  @PropertyMap({ default: null })
  public cellComponent?: ComponentType<any>;

  @PropertyMap({ default: true })
  public exportable!: boolean;

  @PropertyMap({ default: null })
  public contentExport!: (column: ColumnDef<Record>, record: Record) => string;

  @PropertyMap({ default: null })
  public splitExport!: (record: Record) => { name: string; value: any }[];

  @PropertyMap({ default: null, type: [Option] })
  public options!: Option[];

  @PropertyMap({ default: null })
  public default!: Option;

  @PropertyMap({ default: false })
  public multiple!: boolean;

  @PropertyMap({ default: false })
  public hideHeaderName!: boolean;

  @PropertyMap({ default: false })
  public hideName!: boolean;

  @PropertyMap({ default: null })
  public optionsAsync: (
    limit: number,
    skip: number,
    value: string,
  ) => Promise<{ data: ColumnOptionValue[]; matchCount: number }>;

  @PropertyMap({ default: null })
  public startWith: string;

  @PropertyMap({ default: false })
  public fromNow!: boolean;

  @PropertyMap()
  public format!: string;

  @PropertyMap()
  public precision!: number;

  @PropertyMap({ default: {} })
  public metadata!: any;

  public index!: number;

  public left = 0;

  public displaySticky = false;

  public translateX = 0;

  public sort!: Sort | null;

  public getCellData(record: any): ICellData {
    const data: ICellData = {
      value: null,
      color: null,
      bgColor: null,
      icon: null,
      content: this.cellTmpl,
    };
    const recordValue = get(record, this.displayProperty ? this.displayProperty : this.property);
    let option: Option | undefined;

    if (this.displayWith) {
      data.value = this.displayWith(record);
    } else {
      if (['select'].includes(this.type)) {
        if (Array.isArray(recordValue)) {
          data.value = map(
            filter(this.options, (o) => includes(recordValue, o.value)),
            'name',
          ) as string[];
        } else {
          option = find(this.options, (o) => o.value === recordValue);
          if (!option && !isNil(recordValue) && this.default) option = this.default;
          if (!this.hideName) data.value = option ? option.name : recordValue;
        }
      } else if (['button-toggle'].includes(this.type)) {
        option = (this.options || []).find((o) => o.value === recordValue);
        if (!this.hideName) data.value = option ? option.name : recordValue;
      } else if (['checkbox'].includes(this.type)) {
        option = (this.options || []).find((o) => o.value === recordValue);
      } else if (['number'].includes(this.type)) {
        data.value = isNil(recordValue)
          ? recordValue
          : formatNumber(recordValue, 'fr', `.${this.precision || 0}-${this.precision || 0}`);
      } else if (recordValue && this.type === 'date') {
        data.value = this.fromNow ? moment(recordValue).fromNow(true) : moment(recordValue).format(this.format || 'L');
      } else {
        data.value = recordValue;
      }
    }

    if (option && option.color) {
      data.color = option.color;
    } else if (typeof this.color === 'string') {
      data.color = this.color;
    } else if (typeof this.color === 'function') {
      data.color = this.color(record);
    }
    if (option && option.bgColor) {
      data.bgColor = option.bgColor;
    } else if (typeof this.bgColor === 'string') {
      data.bgColor = this.bgColor;
    } else if (typeof this.bgColor === 'function') {
      data.bgColor = this.bgColor(record);
    }

    if (option && option.icon) {
      data.icon = option.icon;
    } else if (!this.displayWith && 'checkbox' === this.type) {
      data.icon = {
        name:
          recordValue === true
            ? 'check_box'
            : recordValue === false
              ? 'check_box_outline_blank'
              : 'indeterminate_check_box',
      };
    } else if (typeof this.prefixIcon === 'string') {
      data.icon = { name: this.prefixIcon };
    } else if (typeof this.prefixIcon === 'function') {
      const icon = this.prefixIcon(record);
      data.icon = typeof icon === 'string' ? { name: icon } : icon;
    }

    if (typeof this.suffix === 'string') {
      data.suffix = this.suffix;
    } else if (typeof this.suffix === 'function') {
      data.suffix = this.suffix(record);
    }

    return data;
  }

  public getExportCellData(record: any): any {
    const recordValue = get(record, this.displayProperty ? this.displayProperty : this.property);
    let value: any;
    if (this.contentExport) {
      value = this.contentExport(this, record);
    } else if (this.displayWith) {
      value = this.displayWith(record);
    } else {
      if (['select', 'button-toggle'].includes(this.type)) {
        const option = (this.options || []).find((o) => o.value === recordValue);
        value = option ? option.name : recordValue;
      } else if (recordValue && this.type === 'date') {
        value = this.fromNow ? moment(recordValue).fromNow(true) : moment(recordValue).format(this.format || 'L');
      } else {
        value = recordValue;
      }
    }
    const rawValue =
      value && value.changingThisBreaksApplicationSecurity ? value.changingThisBreaksApplicationSecurity : value;

    return typeof rawValue === 'string' ? striptags(striptags(rawValue, ['br', 'div', 'li']), [], '\n') : rawValue;
  }
}
