/** @format */

import { animate, style, transition, trigger } from '@angular/animations';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormControl,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { MatPaginator } from '@angular/material/paginator';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {
  clone,
  concat,
  deburr,
  each,
  find,
  get,
  includes,
  isEmpty,
  isEqual,
  isNil,
  join,
  map as lmap,
  merge,
  pick,
  remove,
  toLower,
  trim,
} from 'lodash-es';
import { ModelMapper } from 'model-mapper';
import moment from 'moment';
import { IntersectionObserverEvent } from 'ngx-intersection-observer/lib/intersection-observer-event.model';
import { Subscription, exhaustMap, scan, switchMap } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { Subject } from 'rxjs/internal/Subject';
import { debounceTime, filter, startWith, tap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import * as XLSX from 'xlsx';
import { UserService } from '../../_services/user.service';
import { SessionData } from '../../app.session.data';
import { ActionOption, IAction } from './action.class';
import { ColumnDef, ColumnOption, ColumnOptionValue, Sort } from './column-def.class';
import { DatagridCellDirective } from './datagrid-cell.directive';
import { DatatableDataSource, IRecord, Service } from './datasource';
import { IColumn } from './datatable.class';
import { IIcon } from './icon.class';

export interface IUserConfig {
  configKey: string;
  paginationSize: number;
  columns: {
    label: string;
    index: number;
    show: boolean;
    sticky: boolean;
  }[];
}

export interface IPagination {
  default: number;
  options: (number | 'all')[];
}

export interface ICell {
  index: number;
  rawValue: any;
  value?: SafeHtml | null;
  color: SafeHtml | null;
  bgColor: SafeHtml | null;
  content?: string;
  icon?: IIcon | null;
  inview?: boolean;
  suffix?: string;
}

export interface IRow {
  index: number;
  selected: boolean;
  _id: any;
  cells: (ICell | null)[];
  tooltip?: string | null;
  color?: string | null;
  backgroundColor?: string | null;
}

export interface IDatagridOptions<Record extends IRecord = IRecord> {
  configKey?: string;
  service: Service<Record>;
  columns: ColumnOption[];
  enableRowNumber?: boolean;
  enableSelect?: boolean;
  enableStickyColumn?: boolean;
  enableReorderColumn?: boolean;
  enableHideShowColumns?: boolean;
  enableExport?: boolean;
  enableRefresh?: boolean;
  enableFullscreen?: boolean;
  actionsPosition?: 'top' | 'bottom' | 'floating';
  pagination?: IPagination;
  selected?: Record[];
  showPagination?: 'never' | 'auto' | 'always';
  rowClick?: boolean | ((datagrid: DatagridComponent<Record>, record: Record, $event: Event) => void);
  rowColor?: (record: Record) => string;
  rowBackgroundColor?: (record: Record) => string;
  rowTooltip?: (record: Record) => string;
  actions?: ActionOption<Record>[];
  disableScrollbarModule?: boolean;
  rowHeight?: number;
  heightAuto?: boolean;
  dataMaxHeight?: number;
  sortedColumns?: { column: number; dir: 'asc' | 'desc' }[];
  search?: { [property: string]: any };
  loadOnDisplay?: boolean;
}

@Component({
  selector: 'app-datagrid',
  templateUrl: './datagrid.component.html',
  styleUrls: ['./datagrid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('fade', [
      transition(':enter', [
        style({ opacity: 0 }), // initial
        animate('0.2s', style({ opacity: 1 })), // final
      ]),
      transition(':leave', [
        style({ opacity: 1 }), // initial
        animate('0.2s', style({ opacity: 0 })), // final
      ]),
    ]),
    trigger('fadein', [
      transition(':enter', [
        style({ opacity: 0 }), // initial
        animate('0.2s', style({ opacity: 1 })), // final
      ]),
    ]),
    trigger('fadeout', [
      transition(':leave', [
        style({ opacity: 1 }), // initial
        animate('0.2s', style({ opacity: 0 })), // final
      ]),
    ]),
  ],
})
export class DatagridComponent<Record extends IRecord = IRecord> implements OnInit, AfterViewInit, OnDestroy {
  @Output('rowClick')
  private rowClickEmitter = new EventEmitter<{ datagrid: DatagridComponent<Record>; record: Record; event: Event }>();

  @Input('options')
  public set setOptions(options: IDatagridOptions<Record>) {
    this.configKey = options.configKey;
    this.enableRowNumber = options.enableRowNumber !== undefined ? options.enableRowNumber : this.enableRowNumber;
    this.enableSelect = options.enableSelect !== undefined ? options.enableSelect : this.enableSelect;
    this.enableStickyColumn =
      options.enableStickyColumn !== undefined ? options.enableStickyColumn : this.enableStickyColumn;
    this.enableReorderColumn =
      options.enableReorderColumn !== undefined ? options.enableReorderColumn : this.enableReorderColumn;
    this.enableHideShowColumns =
      options.enableHideShowColumns !== undefined ? options.enableHideShowColumns : this.enableHideShowColumns;
    this.enableExport = options.enableExport !== undefined ? options.enableExport : this.enableExport;
    this.enableRefresh = options.enableRefresh !== undefined ? options.enableRefresh : this.enableRefresh;
    this.enableFullscreen = options.enableFullscreen !== undefined ? options.enableFullscreen : this.enableFullscreen;
    if (options.actionsPosition) {
      this.actionsPosition = options.actionsPosition;
    }
    if (options.pagination) {
      this.pagination = options.pagination;
    }
    if (options.selected) {
      this.selected = options.selected;
    }
    if (options.showPagination) {
      this.showPagination = options.showPagination;
    }
    if (options.service) {
      this.service = options.service;
    }
    if (options.search) {
      this.initialSearch = options.search;
    }
    if (options.sortedColumns) {
      this.sortedColumns = options.sortedColumns;
      // this.initSort();
    }
    if (options.columns) {
      this.buildColumn(options.columns);
    }
    if (options.rowClick) {
      this.rowClickOption = options.rowClick;
    }
    if (options.rowColor) {
      this.rowColorOption = options.rowColor;
    }
    if (options.rowBackgroundColor) {
      this.rowBackgroundColorOption = options.rowBackgroundColor;
    }
    if (options.rowTooltip) {
      this.rowTooltipOption = options.rowTooltip;
    }
    if (options.actions) {
      this.actions = options.actions as any;
    }
    this.disableScrollbarModule =
      options.disableScrollbarModule !== undefined ? options.disableScrollbarModule : this.disableScrollbarModule;
    if (options.rowHeight) {
      this.rowHeight = options.rowHeight;
    }
    this.heightAuto = options.heightAuto === true;
    if (options.dataMaxHeight) {
      this.dataMaxHeight = options.dataMaxHeight;
    }
    if (options.loadOnDisplay === false) {
      this.loadOnDisplay = false;
    }
  }
  public configKey: string | undefined;
  public enableRowNumber = false;
  public enableSelect = false;
  public enableStickyColumn = false;
  public enableReorderColumn = false;
  public enableHideShowColumns = false;
  public enableExport = false;
  public enableRefresh = false;
  public enableFullscreen = false;
  public actionsPosition: 'top' | 'bottom' | 'floating' = 'top';
  public disableScrollbarModule = false;
  public columnMinWidth: number | undefined;
  public rowHeight: number | undefined;
  public heightAuto = false;
  public dataMaxHeight: number | undefined;
  public enteredSticky = false;
  private userConfig: IUserConfig | null | undefined;

  @Output()
  public selectionChanged: EventEmitter<string[]> = new EventEmitter();

  @Output()
  public exported: EventEmitter<void> = new EventEmitter();

  @Output('loading')
  public loadingEmitter: EventEmitter<boolean> = new EventEmitter();

  @Output('ready')
  public readyEmitter: EventEmitter<boolean> = new EventEmitter();

  public rowClickOption:
    | ((datagrid: DatagridComponent<Record>, record: Record, $event: Event) => void)
    | boolean
    | undefined;
  public rowColorOption: ((record: Record) => string) | undefined;
  public rowBackgroundColorOption: ((record: Record) => string) | undefined;
  public rowTooltipOption: ((record: Record) => string) | undefined;

  public actions: IAction<Record>[] = [];

  public pagination: IPagination = { default: 1, options: [10, 20, 50, 100] };
  public showPagination: 'never' | 'auto' | 'always' = 'always';

  public columns: ColumnDef<Record>[] | undefined;
  public headers: ColumnDef<Record>[] | undefined;

  @ContentChildren(DatagridCellDirective)
  private templateRefs: QueryList<DatagridCellDirective> | undefined;
  public templatesByName: any = {};

  public dataSource: DatatableDataSource<Record> | undefined;
  public loading = true;
  public data!: Record[];
  public rows!: IRow[];
  public hasSearchHeader = false;

  public columnsWidth: number | undefined;
  public lastStickyColumnIndex: number | undefined;
  public columnStickyWidth: number | undefined;
  public columDefaultWidth: number | undefined;
  public displayedColumns = { start: 0, end: 0 };
  public scrollWidth = 0;
  public scrollLeft = 0;
  public scrollTop = 0;
  public height: number | undefined;

  public selectAllControl!: UntypedFormControl;
  public selected: Record[] = [];

  public sortedColumns: { column: number; dir: 'asc' | 'desc' }[] = [];

  public updatedHidden:
    | {
        label: string;
        labelParams: string;
        index: number | null;
        show: boolean;
        sticky: boolean;
      }[]
    | null = [];

  public exporting = false;
  public isFullscreen = false;

  public search: UntypedFormGroup;
  private initialSearch: { [property: string]: any } = {};

  private loadOnDisplay = true;

  private loaded = false;

  @Input()
  private service: Service<Record> | undefined;

  @ViewChild('container', { static: true })
  private container: ElementRef<HTMLElement> | undefined;

  @ViewChild(MatPaginator, { static: true })
  private paginator: MatPaginator | undefined;

  private searchSub: Subscription | undefined;
  private subsink = new SubSink();

  public enterStickyPredicate = () => this.enableStickyColumn;

  public selectCompareWidth = (o1: any, o2: any) => o1?.value === o2?.value;

  @HostListener('document:fullscreenchange')
  @HostListener('document:webkitfullscreenchange')
  @HostListener('document:mozfullscreenchange')
  @HostListener('document:MSFullscreenChange')
  fullScreenChange(): void {
    this.isFullscreen = !!document.fullscreenElement;
  }

  constructor(
    public changeDetectorRef: ChangeDetectorRef,
    private elementRef: ElementRef,
    private sanitizer: DomSanitizer,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService,
    private userService: UserService,
  ) {}

  ngOnInit(): void {
    const rowHeight = parseInt(
      window.getComputedStyle(this.elementRef.nativeElement).getPropertyValue('--datagrid-row-height'),
      10,
    );
    if (!this.rowHeight) {
      if (!isNaN(rowHeight)) {
        this.rowHeight = rowHeight;
      } else {
        this.rowHeight = 32;
      }
    }
    const columnMinWidth = parseInt(
      window.getComputedStyle(this.elementRef.nativeElement).getPropertyValue('--datagrid-column-min-width'),
      10,
    );
    this.columnMinWidth = !isNaN(columnMinWidth) ? columnMinWidth : 200;
    this.paginator!.pageSizeOptions = this.pagination.options.map((o) =>
      o === 'all' ? this.dataSource!.recordsFiltered : o,
    );
    this.dataSource = new DatatableDataSource<Record>(this.service!);
    this.initSelection();
  }

  ngAfterViewInit(): void {
    this.templateRefs!.forEach((ref) => (this.templatesByName[ref.name] = ref));
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
    if (this.searchSub) {
      this.searchSub.unsubscribe();
    }
  }

  private loadUserConfig(): void {
    if (this.configKey && SessionData.user) {
      this.userConfig = find(SessionData.user.datagridConfigs, { configKey: this.configKey });
      if (this.userConfig) {
        if (this.paginator) this.paginator.pageSize = this.userConfig.paginationSize;
        each(this.userConfig.columns, (uc) => {
          const columnIndex = this.columns!.findIndex((c) => c.label === uc.label);
          if (columnIndex !== -1) {
            const column = this.columns![columnIndex];
            merge(column, pick(uc, ['show', 'sticky']));
            moveItemInArray(this.columns!, columnIndex, uc.index);
          }
        });
      }
    } else this.userConfig = null;
  }

  private async updateUserConfig(): Promise<any> {
    if (this.configKey && SessionData.user) {
      this.userConfig = {
        configKey: this.configKey,
        paginationSize: this.paginator!.pageSize,
        columns: this.columns!.filter((c) => !c.hidden).map((c) => pick(c, ['label', 'index', 'show', 'sticky'])),
      };
      const userConfig = find(SessionData.user.datagridConfigs, { configKey: this.configKey });
      if (userConfig) merge(userConfig, this.userConfig);
      else SessionData.user.datagridConfigs.push(this.userConfig);
      return this.userService.setDatatableConfig(this.userConfig);
    }
  }

  public async ready(visible: boolean): Promise<void> {
    if (this.loaded || !visible) return;
    this.loaded = true;
    this.subsink.add(
      this.paginator!.page.subscribe(() => {
        this.updateUserConfig();
        this.loadPage();
      }),
      this.dataSource!.connect()
        .pipe(tap((data) => this.loadRecords(data)))
        .subscribe(),
      this.dataSource!.loading$.pipe(
        tap((loading) => this.loadingEmitter.emit((this.loading = loading))),
        tap(() => this.changeDetectorRef.detectChanges()),
      ).subscribe(),
    );
    this.loadUserConfig();
    this.refreshDisplay();
    if (this.loadOnDisplay !== false) this.loadPage();
    this.changeDetectorRef.detectChanges();
    this.readyEmitter.emit(true);
  }

  public cellClick($event: Event, row: IRow, cell: ICell): boolean {
    if (typeof this.rowClickOption !== 'function') {
      const columnDef = this.columns![cell.index];
      if (columnDef && columnDef.click) {
        columnDef.click(this.data![row.index], this);
        $event.preventDefault();
        $event.stopPropagation();
        return false;
      }
    }
    return true;
  }

  public execAction(action: IAction<Record>, menuId?: string): void {
    if (action.menu?.length && !menuId) return;
    action.exec(this, clone(this.selected), menuId);
  }

  public rowClick($event: Event, row: IRow): boolean {
    this.rowClickEmitter.next({ datagrid: this, record: this.data![row.index], event: $event });
    if (typeof this.rowClickOption === 'function') {
      this.rowClickOption(this, this.data![row.index], $event);
      $event.preventDefault();
      $event.stopPropagation();
      return false;
    }
    return true;
  }

  public openHideShowMenu(): void {
    this.updatedHidden = this.columns!.filter((c) => !c.hidden && !includes(['_select', '_rownumber'], c.label)).map(
      (c) => pick(c, ['label', 'labelParams', 'index', 'show', 'sticky']),
    );
  }

  public drop(event: CdkDragDrop<any[]>) {
    this.updatedHidden![event.previousIndex].index = null;
    moveItemInArray(this.updatedHidden!, event.previousIndex, event.currentIndex);
    moveItemInArray(this.columns!, event.previousIndex, event.currentIndex);
  }

  public closeHideShowMenu(): void {
    let updated = false;
    this.updatedHidden!.forEach((u) => {
      const column = this.columns!.find((c) => c.label === u.label);
      if ((u.show && !column!.show) || u.index === null) {
        updated = true;
      }
      column!.show = u.show;
      column!.sticky = u.sticky;
    });
    this.updatedHidden = null;
    this.refreshDisplay();
    if (updated) this.loadPage();
    this.updateUserConfig();
  }

  public fullscreen(): void {
    if (this.isFullscreen) {
      document.exitFullscreen();
    } else {
      this.container!.nativeElement.requestFullscreen();
    }
  }

  public async export(): Promise<void> {
    if (this.exporting) return;
    this.exporting = true;
    try {
      const columns = this.getPageColumns();
      const order = this.getPageOrder(columns);
      const res = await this.dataSource!.fetchData({
        draw: Date.now().toString(),
        columns,
        order,
      });
      const data = [];
      if (res?.data) {
        for (const record of res.data) {
          const row: any = {};
          for (const column of this.columns!) {
            if (column.exportable && !column.hidden && column.show) {
              if (column.splitExport) {
                each(column.splitExport(record), (exp) => (row[exp.name] = exp.value));
              } else {
                row[await this.translate.get(column.label).toPromise()] = column.getExportCellData(record);
              }
            }
          }
          data.push(row);
        }
      }
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(data);
      XLSX.utils.book_append_sheet(workbook, worksheet, 'export');
      await XLSX.writeFile(workbook, `datagrid-${res.draw || Date.now()}.xlsx`);
    } finally {
      this.exported.emit();
      this.exporting = false;
      this.changeDetectorRef.detectChanges();
    }
  }

  public loadPage(): void {
    if (!this.dataSource) return;
    const columns = this.getPageColumns();
    const order = this.getPageOrder(columns);
    let options = {
      draw: Date.now().toString(),
      columns,
      order,
    };
    if (this.showPagination !== 'never') {
      options = merge(options, {
        start: this.paginator!.pageIndex,
        length: this.paginator!.pageSize,
      });
    }
    this.dataSource!.loadData(options);
  }

  public refreshDisplay(): void {
    this.columns!.forEach((c, i) => (c.index = i));
    this.rows = this.data?.map((r, i) => this.buildRow(r, i));
    this.buildDisplayedColumns();
    this.changeDetectorRef.detectChanges();
  }

  public getPageColumns(): IColumn[] {
    const columns: IColumn[] = [];
    const missingColumns: IColumn[] = [];
    (this.columns || []).forEach((columnDef, i) => {
      if (columnDef.type === 'action' || (!columnDef.hidden && !columnDef.show)) return;
      const column: IColumn = {
        data: columnDef.property,
        name: columnDef.label,
        orderable: get(columnDef, 'sortable', true),
        searchable: get(columnDef, 'searchable', true),
      };
      this.addColumnSearch(columnDef, column);
      if (!columns.find((c) => c.data === column.data)) columns.push(column);
      this.addLoadPageMissingColumns(missingColumns, columnDef);
    });
    missingColumns.forEach((mc) => {
      if (!columns.find((c) => c.data === mc.data)) columns.push(mc);
    });
    return columns;
  }

  private addLoadPageMissingColumns(columns: IColumn[], columnDef: ColumnDef<Record>): void {
    if (columnDef.searchProperty) {
      // if (columnDef.searchProperty && !columnDef.searchProperty.startsWith(`${columnDef.property}.`)) {
      const column: IColumn = {
        data: columnDef.searchProperty,
        name: columnDef.label,
        searchable: true,
        orderable: columnDef.sortable,
      };
      this.addColumnSearch(columnDef, column);
      if (column.search) columns.push(column);
    }
    if (columnDef.sortProperty && !columnDef.sortProperty.startsWith(`${columnDef.property}.`)) {
      const column: IColumn = {
        data: columnDef.sortProperty,
        name: columnDef.label,
        searchable: columnDef.searchable,
        orderable: true,
      };
      if (this.sortedColumns.find((sc) => sc.column === columnDef.index)) {
        columns.push(column);
      }
    }
    if (columnDef.displayProperty) {
      const column: IColumn = {
        data: columnDef.displayProperty,
        name: columnDef.label,
        searchable: true,
        orderable: true,
      };
      this.addColumnSearch(columnDef, column);
      columns.push(column);
    }
    if (columnDef.linkedProperties && columnDef.linkedProperties.length) {
      columnDef.linkedProperties.forEach((property) =>
        columns.push({
          data: property,
          name: columnDef.label,
          searchable: true,
          orderable: true,
        }),
      );
    }
  }

  private addColumnSearch(columnDef: ColumnDef<Record>, column: IColumn): void {
    if (column.searchable) {
      const control = this.search.controls[column.data];
      if (control && !isNil(control.value)) {
        switch (columnDef.type) {
          case 'select':
          case 'autocomplete':
          case 'button-toggle':
            column.search = {
              value: columnDef.multiple ? lmap(control.value, 'value') : control.value.value,
              regex: false,
            };
            break;
          case 'date':
            this.addDateColumnSearch(columnDef, column, control);
            break;
          case 'number':
            if (!isEmpty(trim(control.value))) column.search = { value: Number(control.value) };
            break;
          default:
            if (!isEmpty(trim(control.value))) {
              const value = columnDef.strict ? deburr(toLower(trim(control.value))) : control.value;
              column.search = { value, regex: true };
            }
        }
      }
    }
  }

  private addDateColumnSearch(columnDef: ColumnDef<Record>, column: IColumn, control: AbstractControl<any>): void {
    if (!control.value?.from) return;
    const value: any = {
      op: control.value?.op,
      from: control.value?.from.toDate(),
      to: control.value?.to?.toDate(),
    };
    switch (value.op) {
      case '=':
        value.op = '>=<';
        value.to = moment(value.from).add(1, 'day').toDate();
        break;
    }
    column.search = { value };
  }

  private getPageOrder(columns: IColumn[]): { column: number; dir: string }[] {
    const order: { column: number; dir: string }[] = [];
    this.sortedColumns.forEach((sc) => {
      const property =
        this.columns![sc.column].sortProperty ||
        this.columns![sc.column].displayProperty ||
        this.columns![sc.column].property;
      const ci = columns.findIndex((c) => c.data === property && c.orderable);
      if (ci !== -1) {
        order.push({ column: ci, dir: sc.dir });
      }
    });
    return order;
  }

  private loadRecords(records: Record[]): void {
    this.data = records;
    this.rows = this.data.map((r, i) => this.buildRow(r, i));
    this.updateSelectState();
    this.changeDetectorRef.detectChanges();
  }

  private buildRow(record: Record, index: number): IRow {
    const row: IRow = {
      index,
      _id: record._id,
      cells: [],
      selected: this.selected.findIndex((s) => s._id === record._id) !== -1,
      tooltip: this.rowTooltipOption ? this.rowTooltipOption(record) : null,
      color: this.rowColorOption ? this.rowColorOption(record) : null,
      backgroundColor: this.rowBackgroundColorOption ? this.rowBackgroundColorOption(record) : null,
    };
    this.columns!.forEach((columnDef, ci) => {
      if (!columnDef.hidden) {
        row.cells.push(this.getCellData(ci, columnDef, record));
      } else {
        row.cells.push(null);
      }
    });
    return row;
  }

  private getCellData(index: number, columnDef: ColumnDef<Record>, record: Record): ICell {
    const data = columnDef.getCellData(record);
    let value: any = data.value;
    if (!isNil(data.value) && !isEmpty(data.value)) {
      if (columnDef.translateValue) {
        if (Array.isArray(value)) value = Object.values(this.translate.instant(data.value));
        else value = typeof data.value === 'string' ? this.translate.instant(data.value) : data.value;
      }
      if (Array.isArray(value)) value = join(value, ', ');
      value = this.sanitizer.bypassSecurityTrustHtml(value);
    }

    return {
      index,
      content: columnDef.cellTmpl,
      rawValue: get(record, columnDef.displayProperty ? columnDef.displayProperty : columnDef.property),
      value,
      color: data.color ? this.sanitizer.bypassSecurityTrustHtml(data.color) : null,
      bgColor: data.bgColor ? this.sanitizer.bypassSecurityTrustHtml(data.bgColor) : null,
      icon: data.icon,
      suffix: data.suffix,
    };
  }

  private buildColumn(columns: ColumnOption[]): void {
    if (this.searchSub) this.searchSub.unsubscribe();
    this.hasSearchHeader = false;
    this.search = this.formBuilder.group({});
    const statics = [];
    if (this.enableSelect) {
      statics.push({
        type: 'action',
        label: '_select',
        show: true,
        sticky: true,
        width: 40,
      });
    }
    if (this.enableRowNumber) {
      statics.push({
        type: 'action',
        label: '_rownumber',
        show: true,
        sticky: true,
        width: 40,
      });
    }
    this.columns = concat(statics as any[], columns).map((c) => new ModelMapper(ColumnDef<Record>).map(c));
    this.columns!.forEach((c, i) => {
      c.index = i;
      if (this.enableStickyColumn === false) c.sticky = false;
      if (c.sortable) {
        const sort = find(this.sortedColumns, { column: i });
        if (sort) c.sort = Sort.build(sort);
      }
      if (c.searchable) {
        this.hasSearchHeader = true;
        const property = c.searchProperty || c.displayProperty || c.property;

        let control: FormGroup | FormControl;
        if (c.type === 'date') {
          control = this.formBuilder.group({
            op: ['='],
            from: [get(this.initialSearch, property)?.from],
            to: [get(this.initialSearch, property)?.to],
          });
        } else control = new FormControl(get(this.initialSearch, property));
        this.search.addControl(property, control);

        if (c.type === 'autocomplete') {
          control.setValue('', { emitEvent: false });
          const nextPage$ = new Subject<void>();
          (control as any).loadMore = ($event: IntersectionObserverEvent) => {
            if (!$event.intersect) return;
            nextPage$.next();
          };
          const asynOptionsControl = ((control as any).asynOptionsControl = new UntypedFormControl());
          const filter$: Observable<string> = asynOptionsControl.valueChanges.pipe(
            filter((value) => typeof value === 'string'),
            debounceTime(300),
            tap(() => ((control as any).optionsAsyncLoading = true)),
          );
          (control as any).optionsAsync = filter$.pipe(
            switchMap((search: string) => {
              let skip = 0;
              return nextPage$.pipe(
                startWith(skip),
                exhaustMap(async () =>
                  typeof search === 'string'
                    ? c.optionsAsync(10, skip, trim(search))
                    : { data: [] as ColumnOptionValue[], matchCount: 0 },
                ),
                tap(() => (skip += 10)),
                tap((list) => ((control as any).searchMatched = list.matchCount)),
                scan((data, list) => data.concat(list.data), [] as ColumnOptionValue[]),
              );
            }),
            tap(() => ((control as any).optionsAsyncLoading = false)),
          );
        }
      }
    });
    let lastSearch: any = this.search.value;
    this.searchSub = this.search.valueChanges
      .pipe(
        debounceTime(500),
        filter((value) => !isEqual(lastSearch, value)),
        tap((value) => (lastSearch = value)),
        tap(() => (this.paginator!.pageIndex = 0)),
      )
      .subscribe(() => this.loadPage());
    this.changeDetectorRef.detectChanges();
  }

  private buildDisplayedColumns(): void {
    this.headers = [];
    this.columns!.forEach((column) => {
      if (column.hidden || !column.show) return;
      this.headers!.push(column);
    });
  }

  public sortColumn(columnDef: ColumnDef): void {
    if (columnDef.sortable === false) return;
    const i = this.sortedColumns.findIndex((c) => c.column === columnDef.index);
    if (i === -1) {
      this.sortedColumns.push({ column: columnDef.index, dir: 'asc' });
      columnDef.sort = new ModelMapper(Sort).map({
        dir: 'asc',
        position: this.sortedColumns.length,
      });
    } else {
      const sort = this.sortedColumns[i];
      if (sort.dir === 'asc') {
        sort.dir = 'desc';
        columnDef.sort!.dir = 'desc';
      } else {
        this.sortedColumns.splice(i, 1);
        columnDef.sort = null;
        this.sortedColumns.slice(i).forEach((sc) => (this.columns![sc.column].sort!.position -= 1));
      }
    }
    this.paginator!.pageIndex = 0;
    this.loadPage();
  }

  public select(row: IRow): void {
    row.selected = !row.selected;
    remove(this.selected, (s) => s._id === row._id);
    if (row.selected) {
      this.selected.push(this.data![row.index]);
    }
    this.updateSelectState();
    this.selectionChanged.emit(lmap(this.selected, '_id'));
  }

  private initSelection(): void {
    this.selectAllControl = new UntypedFormControl(false);
    this.updateSelectState();
    this.selectAllControl.valueChanges.subscribe((value) => {
      this.rows!.forEach((row) => {
        row.selected = value;
        remove(this.selected, (s) => s._id === row._id);
        if (row.selected) {
          this.selected.push(this.data![row.index]);
        }
      });
      this.updateSelectState();
      this.selectionChanged.emit(lmap(this.selected, '_id'));
    });
  }

  private updateSelectState(): void {
    const unselectedData = !!(this.rows || []).find((row) => this.selected.findIndex((s) => s._id === row._id) === -1);
    const value = !this.selected.length ? false : unselectedData ? null : true;
    this.selectAllControl!.setValue(value, { emitEvent: false });
    this.actions.forEach((a) => {
      a.isHidden = a.checkHidden ? a.checkHidden(this.selected) : a.onSelectedOnly ? !this.selected.length : a.isHidden;
      a.isDisabled = a.checkDisabled ? a.checkDisabled(this.selected) : a.isDisabled;
    });
  }
}
